import axios from "axios"

export const state = () => ({
  main_cs: null,
  main_en: null,
  footer_cs: null,
  footer_en: null,
})

export const mutations = {
  SET_NAVIGATION(state, payload) {
    state[payload.key] = payload.value;
  },
}

export const actions = {
  async fetchNavigation({ commit }) {
    const apiUrl = process.env.apiUrl
    const { data: mainCS } = await axios.get(apiUrl + "/api/navigation/render/main-cz?type=TREE")
    commit("SET_NAVIGATION", {
      key: "main_cs",
      value: mainCS,
    })

    const { data: mainEN } = await axios.get(apiUrl + "/api/navigation/render/main-en?type=TREE")
    commit("SET_NAVIGATION", {
      key: "main_en",
      value: mainEN,
    })

    const { data: footerCS } = await axios.get(apiUrl + "/api/navigation/render/footer-cz?type=TREE")
    commit("SET_NAVIGATION", {
      key: "footer_cs",
      value: footerCS,
    })

    const { data: footerEN } = await axios.get(apiUrl + "/api/navigation/render/footer-en?type=TREE")
    commit("SET_NAVIGATION", {
      key: "footer_en",
      value: footerEN,
    })
  },
}

export const getters = {
  mainCS: (state) => state.main_cs,
  mainEN: (state) => state.main_en,
  footerCS: (state) => state.footer_cs,
  footerEN: (state) => state.footer_en,
}
