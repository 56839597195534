
export default {
  name: "Calendar",
  props: {
    clear: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: '',
      isMobile: false,
      attributes: [
        {
          key: 'today',
          highlight: {
            class: 'today'
          },
          dates: new Date().toISOString().slice(0, 10),
        },
      ],
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true;
    }
  },
  watch: {
    date() {
      if (this.date !== '' && this.date !== null) {
        this.$emit("getDate", this.date?.toISOString().slice(0, 10))
      }
    },
    clear() {
      if (this.clear) {
        this.date = ''
      } return null
    }
  },
}
