
export default {
  name: 'Input',
  props: {
    label: {
      type: String,
      default: 'label'
    },
    type: {
      type: String,
      default: 'text'
    },
    white: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    clear: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    clear() {
      if (this.clear) {
        this.value = ''
      } return null
    }
  },
  data() {
    return {
      active: false,
      value: ''
    }
  },
}
