
import getEventState from '~/mixins/getEventState'
import addComa from '~/mixins/addComa'
import locale from '~/mixins/locale'
import format from '~/mixins/format'

export default {
  name: 'EventCard',
  mixins: [format, locale, getEventState, addComa],
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'title'
    },
    date_from: {
      type: String,
      default: null
    },
    date_to: {
      type: String,
      default: null
    },
    time_from: {
      type: String,
      default: null
    },
    time_to: {
      type: String,
      default: null
    },
    categories: {
      type: Array,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    tags: {
      type: [Array, Object],
      default: null
    },
    producer: {
      type: String,
      default: null
    },
    url: {
      type: Object,
      default: ''
    },
    span: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hovered: false,
      api: process.env.apiUrl,
      isMobile: false
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true
    }
  },
}
