
import serp from '~/mixins/serp'
import localizeUrl from '~/mixins/localizeUrl'
import locale from '~/mixins/locale'
import query from '~/helpers/query'

export default {
  name: 'PressReleaseDetail',
  mixins: [serp, locale, localizeUrl],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/press-realeses?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main',
        'constructor_right',
        'constructor_right.image',
        'constructor_right.upload_files',
        'constructor_right.mf_staff',
        'upload_images',
        'related_events.main_image',
        'related_events.event_info',
        'related_events.categories',
        'related_events.special_tags',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const contacts = await $axios.get('/api/site-setting?' + query({
      locale: store.getters['locale'],
      filters: null,
      populate: [
        'staff.mf_staffs'
      ]
    }))

    return {
      page: data[0],
      contacts: contacts,
      localization: data[0]?.localizations?.data[0]?.attributes,
      api: process.env.apiUrl,
    }
  },
  computed: {
    getAllContacts() {
      let allContacts = this.contacts?.staff
      allContacts = allContacts.find(contact => contact.section_title.includes('PR')).mf_staffs.data
      return allContacts
    },
  },
  data() {
    return {
      isMobile: false
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true
    }
  },
}
