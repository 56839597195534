
export default {
  name: 'NavMobile',
  methods: {
    changeLang() {
      this.$store.dispatch('cycleLocale')
      this.$router.replace({
        name: this.$getUrl(this.$route.name.replace("en--", ""))
      })
    },
  }
}
