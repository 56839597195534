
import serp from '~/mixins/serp'
import isMore from '~/mixins/isMore'
import today from '~/mixins/today'
import query from '~/helpers/query'

export default {
  name: 'MusicIndex',
  mixins: [serp, isMore, today],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/music-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
      ],
      filters: {
        key: {
          $eq: 'archive'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const eventsMusic = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      filters: {
        $and: [
          {
            categories: {
              key: {
                $eq: 'music'
              }
            }
          },
          {
            $or: [
              {
                $and: [
                  {
                    event_info: {
                      date_from: {
                        $lt: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                  {
                    event_info: {
                      date_to: {
                        $null: true
                      }
                    }
                  }
                ]
              },
              {
                $and: [
                  {
                    event_info: {
                      date_from: {
                        $lt: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                  {
                    event_info: {
                      date_to: {
                        $lt: new Date().toISOString().slice(0, 10)
                      }
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      sort: [
        'event_info.date_from:desc'
      ],
      pagination: {
        page: 1,
        pageSize: 6,
      }
    }))

    return {
      page: data[0],
      events: eventsMusic,
      paginationPage: 1,
      filters: {
        year: null,
        search: null
      }
    }
  },
  watch:{
    filters:{
      handler(){
        this.events = []
        this.paginationPage = 0
        this.loadEvents()
      },
      deep: true
    }
  },
  methods: {
    async loadEvents() {
      this.paginationPage++

      const filterByTitle = this.filters.search ? {
        page_title: {
          $containsi: this.filters.search
        }
      } : null

      const filterByYear = this.filters.year ? {
        $or: [
          {
            event_info: {
              date_from: {
                $containsi: this.filters.year
              }
            }
          },
          {
            event_info: {
              date_to: {
                $containsi: this.filters.year
              }
            }
          }
        ]
      } : null

      const eventsMusic = await this.$axios.get('/api/events?' + query({
        locale: this.$store.getters['locale'],
        filters: {
        $and: [
          {
            categories: {
              key: {
                $eq: 'music'
              }
            }
          },
          {
            $or: [
              {
                $and: [
                  {
                    event_info: {
                      date_from: {
                        $lt: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                  {
                    event_info: {
                      date_to: {
                        $null: true
                      }
                    }
                  }
                ]
              },
              {
                $and: [
                  {
                    event_info: {
                      date_from: {
                        $lt: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                  {
                    event_info: {
                      date_to: {
                        $lt: new Date().toISOString().slice(0, 10)
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            ...filterByYear
          },
          {
            ...filterByTitle
          }
        ]
      },
        sort: [
          'event_info.date_from:desc'
        ],
        pagination: {
          page: this.paginationPage,
          pageSize: 6,
        }
      }))
      this.events.push(...eventsMusic)
    },
    handleYearFilter(filteredYear) {
      this.filters.year = filteredYear
      this.filters.search = null
    },
    handleSearchFilter(filteredSearch) {
      this.filters.year = null
      this.filters.search = filteredSearch
    },
  }
}
