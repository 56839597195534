
import serp from '~/mixins/serp'
import isMore from '~/mixins/isMore'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'TheatreRepertoire',
  mixins: [serp, isMore, getAllImages],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/theater-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'partners.image',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        key: {
          $eq: 'current-repertoire'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const eventsTheatre = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            $and: [
              {
                categories: {
                  key: {
                    $eq: 'theater'
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          },
          {
            $and: [
              {
                categories: {
                  key: {
                    $eq: 'theater'
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $lte: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                event_info: {
                  date_to: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          }
        ]
      },
      sort: [
        'event_info.date_from:asc'
      ],
      pagination: {
        page: 1,
        pageSize: 6,
      }
    }))
    
    return {
      page: data[0],
      events: eventsTheatre,
      paginationPage: 1,
      filters: {
        search: null,
      }
    }
  },
  watch:{
    filters:{
      handler(){
        this.events = []
        this.paginationPage = 0
        this.loadEvents()
      },
      deep: true
    }
  },
  methods: {
    async loadEvents() {
      this.paginationPage++

      const filterByTitle = this.filters.search ? {
        page_title: {
          $containsi: this.filters.search
        }
      } : null

      const eventsTheatre = await this.$axios.get('/api/events?' + query({
        locale: this.$store.getters['locale'],
        filters: {
          $and: [
            {
              $or: [
                {
                  $and: [
                    {
                      categories: {
                        key: {
                          $eq: 'theater'
                        }
                      }
                    },
                    {
                      event_info: {
                        date_from: {
                          $gte: new Date().toISOString().slice(0, 10)
                        }
                      }
                    }
                  ]
                },
                {
                  $and: [
                    {
                      categories: {
                        key: {
                          $eq: 'theater'
                        }
                      }
                    },
                    {
                      event_info: {
                        date_from: {
                          $lte: new Date().toISOString().slice(0, 10)
                        }
                      }
                    },
                    {
                      event_info: {
                        date_to: {
                          $gte: new Date().toISOString().slice(0, 10)
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              ...filterByTitle
            }
          ]
        },
        sort: [
          'event_info.date_from:asc'
        ],
        pagination: {
          page: this.paginationPage,
          pageSize: 6,
        }
      }))
      this.events.push(...eventsTheatre)
    },
    handleSearchFilter(filteredSearch) {
      this.filters.search = filteredSearch
    },
  }
}
