
export default {
  name: 'Chechbox',
  data() {
    return {
      checked: ''
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.checked)
    }
  }
}
