
export default {
    name: 'Button',
    props: {
        type: {
            type: String,
            default: 'primary'
        },
        label: {
            type: String,
            default: 'label'
        },
        icon: {
            type: String,
            default: null
        },
        url: {
            type: [Object, String],
            default: ''
        },
        span: {
            type: Boolean,
            default: false
        }
    }
}
