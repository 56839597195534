
const seoFactory = (store, page, url) => {

  const siteName = store.getters.siteSettings?.serp?.serp_title || "MeetFactory"
  const serp = page?.serp
  const social = page?.social_sharing
  const defaultTitle = page?.page_title
  const api = process.env.apiUrl
  const returnObj = {
    title: defaultTitle ? `${defaultTitle} | ${siteName}` : siteName,
  }
  let location
  if (process.client) {
    location = window.location.origin
  }

  if (serp && serp?.serp_title)
    returnObj.title = serp?.serp_title + " | " + siteName

  if (serp && serp?.serp_description_meta && serp?.serp_description_meta !== "") {
    returnObj.meta = []
    returnObj.meta.push({
      hid: "description",
      name: "description",
      content: serp?.serp_description_meta,
    })
  }

  if (!returnObj.meta) returnObj.meta = []

  if (social && social?.social_title) {
    returnObj.meta.push(
      {
        hid: "og:title",
        property: "og:title",
        content: social?.social_title,
      },
      {
        property: "twitter:title",
        content: social?.social_title,
      }
    )
  } else if (page && defaultTitle) {
    returnObj.meta.push(
      {
        hid: "og:title",
        property: "og:title",
        content: defaultTitle,
      },
      {
        property: "twitter:title",
        content: defaultTitle,
      }
    )
  }

  if (social && social?.social_description) {
    returnObj.meta.push(
      {
        hid: "og:description",
        property: "og:description",
        content: social?.social_description,
      },
      {
        property: "twitter:description",
        content: social?.social_description,
      }
    )
  } else if (page && page?.introtext) {
    returnObj.meta.push(
      {
        hid: "og:description",
        property: "og:description",
        content: page?.introtext,
      },
      {
        property: "twitter:description",
        content: page?.introtext,
      }
    )
  }
  
  if (url && location) {
    returnObj.meta.push(
      {
        hid: "og:url",
        property: "og:url",
        content: location + url,
      },
      {
        hid: "twitter:url",
        property: "twitter:url",
        content: location + url,
      }
    )
  }

  returnObj.meta.push(
    {
      hid: "og:type",
      property: "og:type",
      content: 'article',
    }
  )

  if (social && social?.social_image && social?.social_image?.data) {
    returnObj.meta.push(
      {
        hid: "og:image",
        property: "og:image",
        content: api + social?.social_image.data.attributes.url,
      },
      {
        property: "twitter:image",
        content: api + social?.social_image.data.attributes.url,
      },
      {
        hid: "t-type",
        name: "twitter:card",
        content: "summary_large_image",
      }
    )
  } else if (
    store.getters.siteSettings?.social_sharing_default_img &&
    store.getters.siteSettings?.social_sharing_default_img?.data
  ) {
    returnObj.meta.push(
      {
        hid: "og:image",
        property: "og:image",
        content:
          api +
          store.getters.siteSettings?.social_sharing_default_img.data
            .attributes.url,
      },
      {
        property: "twitter:image",
        content:
          api +
          store.getters.siteSettings?.social_sharing_default_img.data
            .attributes.url,
      },
      {
        hid: "t-type",
        name: "twitter:card",
        content: "summary_large_image",
      }
    )
  }
  return returnObj
};
export default ({ store }, inject) => {
  inject("seo", {
    generate(page, url) {
      return seoFactory(store, page, url)
    },
  })
}
