
export default {
  name: 'BigButton',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String,
      default: 'label'
    },
    url: {
      type: [Object, String],
      default: ''
    }
  }
}
