
export default {
  name: 'Default',
  data() {
    return {
      isMobile: false,
      news: false
    }
  },
  mounted() {
    if (window.innerWidth < 1024) {
      this.isMobile = true
    }
  },
}
