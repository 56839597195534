import { render, staticRenderFns } from "./index.vue?vue&type=template&id=41abc6e5&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageHeroSection: require('/home/runner/work/meetfactory-2/meetfactory-2/components/HomepageHeroSection.vue').default,MobileHomepageHeroSection: require('/home/runner/work/meetfactory-2/meetfactory-2/components/MobileHomepageHeroSection.vue').default,MobileOpenCallHeaderDetail: require('/home/runner/work/meetfactory-2/meetfactory-2/components/MobileOpenCallHeaderDetail.vue').default,Heading: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Heading.vue').default,EventCard: require('/home/runner/work/meetfactory-2/meetfactory-2/components/EventCard.vue').default,Devider: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Devider.vue').default,CategoryItem: require('/home/runner/work/meetfactory-2/meetfactory-2/components/CategoryItem.vue').default,Calendar: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Calendar.vue').default,CalendarItem: require('/home/runner/work/meetfactory-2/meetfactory-2/components/CalendarItem.vue').default,InformationSection: require('/home/runner/work/meetfactory-2/meetfactory-2/components/InformationSection.vue').default})
