
import serp from '~/mixins/serp'
import query from '~/helpers/query'
import getCategory from '~/mixins/getCategory'
import today from '~/mixins/today'

export default {
  name: 'Homepage',
  mixins: [serp, getCategory, today],
  async asyncData({ $axios, store }) {
    const page = await $axios.get('/api/homepage?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'planned_events',
        'planned_events.image',
        'display_events.dont_miss.localization',
        'display_events.dont_miss.categories',
        'display_events.dont_miss.main_image',
        'display_events.dont_miss.event_info',
        'display_events.dont_miss.producer',
        'display_events.dont_miss.special_tags',
      ],
      filters: null
    }))

    const eventsToday = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      populate: [
        'localizations',
        'categories',
        'main_image',
        'event_info',
        'producer',
        'special_tags',
        'subevent.event_info',
        'subevent.producer',
        'subevent.special_tags',
      ],
      filters: {
        $or: [
          {
            event_info: {
              date_from: {
                $eq: new Date().toISOString().slice(0, 10)
              }
            }
          },
          {
            $and: [
              {
                event_info: {
                  date_from: {
                    $lte: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                event_info: {
                  date_to: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              },
            ]
          }
        ]
      },
      sort: [
        'event_info.date_from:asc'
      ],
      pagination: {
        start: 0,
        limit: page.hp_current_events_list
      }
    }))

    const eventsCurrent = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      populate: [
        'localizations',
        'categories',
        'main_image',
        'event_info',
        'producer',
        'special_tags',
        'subevent.event_info',
        'subevent.producer',
        'subevent.special_tags',
      ],
      filters: {
        $and: [
          {
            event_info: {
              display_in_current: {
                $eq: true
              }
            }
          },
          {
            event_info: {
              date_from: {
                $gt: new Date().toISOString().slice(0, 10)
              }
            }
          },
        ]
      },
      sort: [
        'event_info.date_from:asc'
      ],
      pagination: {
        start: 0,
        limit: page.hp_current_events_list
      }
    }))

    const eventsTheater = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      filters: {
        $and: [
          {
            $or: [
              {
                event_info: {
                  date_from: {
                    $eq: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                $and: [
                  {
                    event_info: {
                      date_from: {
                        $lte: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                  {
                    event_info: {
                      date_to: {
                        $gte: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                ]
              }
            ]
          },
          {
            categories: {
              key: {
                $eq: 'theater'
              }
            }
          }
        ]
      },
      sort: [
        'event_info.date_from:desc'
      ],
      pagination: {
        start: 0,
        limit: page.hp_current_theater_list
      }
    }))

    const eventsExhibition = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      filters: {
        $and: [
          {
            $or: [
              {
                event_info: {
                  date_from: {
                    $eq: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                $and: [
                  {
                    event_info: {
                      date_from: {
                        $lte: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                  {
                    event_info: {
                      date_to: {
                        $gte: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                ]
              }
            ]
          },
          {
            type_of_event: {
              key: {
                $eq: 'exhibition'
              }
            }
          }
        ]
      },
      sort: [
        'event_info.date_from:desc'
      ],
      pagination: {
        start: 0,
        limit: page.hp_current_exhibition_list
      }
    }))

    const openCalls = await $axios.get('/api/open-calls?' + query({
      locale: store.getters['locale'],
      filters: {
        pin_to_homepage: {
          $eq: true
        }
      }
    }))

    const podcasts = await $axios.get('/api/podcasts?' + query({
      locale: store.getters['locale'],
    }))

    const contact = await $axios.get('/api/site-setting?')
    
    return {
      api: process.env.apiUrl,
      contact: contact,
      page: page,
      plannedEvents: page.planned_events,
      eventsToday: eventsToday,
      eventsCurrent: eventsCurrent,
      allFilteredEvents: [],
      eventsExhibition: eventsExhibition,
      eventsTheater: eventsTheater,
      eventsDontMiss: page.display_events?.dont_miss?.data.slice(0, page.hp_dontmiss_list),
      eventsCalendar: [],
      openCalls: openCalls,
      filteredOpenCalls: [],
      podcasts: podcasts,
      filteredPodcasts: [],
    }
  },
  computed: {
    sortedEventDontMiss() {
      let singleEvents = []
      let rangeEvents = [] 
      if (this.eventsDontMiss.filter(event => event.attributes?.event_info.date_to === null).length) {
        singleEvents = this.eventsDontMiss.filter(event => event.attributes?.event_info.date_from >= this.today)
      }
      if (this.eventsDontMiss.filter(event => event.attributes?.event_info.date_to !== null).length) {
        rangeEvents = this.eventsDontMiss.filter(event => event.attributes?.event_info.date_from <= this.today && event.attributes?.event_info.date_to >= this.today)
      }
      let sortedEventDontMiss = [...singleEvents, ...rangeEvents]
      sortedEventDontMiss = sortedEventDontMiss.sort(function (a, b) {
        return (Date.parse(a.attributes?.event_info.date_from) > Date.parse(b.attributes?.event_info.date_from)) ? 1 : (Date.parse(a.attributes?.event_info.date_from) < Date.parse(b.attributes?.event_info.date_from)) ? -1 : 0
      })
      return sortedEventDontMiss
    },
    getFilteredEvents() {
      let events = [...this.eventsToday, ...this.eventsCurrent]
      let allFilteredEvents = []
      let openCallsArray = this.openCalls
      let episodes = []
      let singleEvents = []
      let singleRangeEvents = []
      let subevents = []
      let array4 = []
      let array5 = []
      events = events.slice(0, this.page.hp_current_events_list)
      if (events.filter(event => !event.subevent?.length && event.event_info?.date_to === null)?.length) {
        singleEvents = events.filter(event => !event?.subevent?.length && event.event_info?.date_to === null)
      }
      if (events.filter(event => !event.subevent?.length && event.event_info?.date_to !== null)?.length) {
        singleRangeEvents = events.filter(event => !event.subevent?.length && event.event_info?.date_to !== null && event.event_info?.date_to >= this.today)
      }
      if (events.filter(event => event.subevent?.length && event.event_info?.date_to === null)?.length) {
        events.filter(event => event.subevent?.length && event.event_info?.date_to === null).forEach(event => {
          if (
            event.subevent?.filter(subevent => subevent.event_info?.date_from <= this.today && subevent.event_info?.date_to >= this.today && subevent.event_info.display_in_current === true).length ||
            event.subevent?.filter(subevent => subevent.event_info?.date_from === this.today && subevent.event_info.display_in_current === true).length
          ) {
            event?.subevent.filter(subevent => subevent.event_info?.display_in_current === true).forEach(subevent => {
              subevent.main_image = event.main_image
              subevent.categories = event.categories
              subevent.introtext = event.introtext
              subevent.url_alias = event.url_alias
              
              subevents.push(subevent)
            })
          } else {
            array4 = events.filter(event => event.subevent?.length)
          }
        })
      }
      if (events.filter(event => event.subevent?.length && event.event_info?.date_to !== null)?.length) {
        events.filter(event => event.subevent?.length && event.event_info?.date_to !== null && event.event_info?.date_to >= this.today).forEach(event => {
          if (
            event.subevent?.filter(subevent => subevent.event_info?.date_from <= this.today && subevent.event_info?.date_to >= this.today && subevent.event_info.display_in_current === true).length ||
            event.subevent?.filter(subevent => subevent.event_info?.date_from === this.today && subevent.event_info.display_in_current === true).length
          ) {
            event?.subevent.filter(subevent => subevent.event_info?.display_in_current === true).forEach(subevent => {
              subevent.main_image = event.main_image
              subevent.categories = event.categories
              subevent.introtext = event.introtext
              subevent.url_alias = event.url_alias

              subevents.push(subevent)
            })
          } else {
            array5 = events.filter(event => event.subevent?.length)
          }
        })
      }
      allFilteredEvents = [...singleEvents, ...singleRangeEvents, ...subevents, ...array4, ...array5]
      allFilteredEvents = allFilteredEvents.sort(function (a, b) {
        return (Date.parse(a.event_info.date_from) > Date.parse(b.event_info.date_from)) ? 1 : (Date.parse(a.event_info.date_from) < Date.parse(b.event_info.date_from)) ? -1 : 0
      })
      this.allFilteredEvents = allFilteredEvents
      if (openCallsArray.filter(call => call.deadline_date?.length)) {
        openCallsArray = openCallsArray.filter(call => call.deadline_date?.length)
        this.filteredOpenCalls = openCallsArray
      }
      if (this.podcasts.filter(podcast => podcast.podcasts?.length)) {
        this.podcasts.filter(podcast => podcast.podcasts?.length).forEach(podcast => {
          podcast.podcasts?.forEach(episode => {
            episode.url_alias = podcast.url_alias
            episode.page_title = episode.name
            episode.main_image = podcast.main_image
          })
          episodes = podcast.podcasts?.filter(episode => episode.pin_to_start === true)
          this.filteredPodcasts = episodes
        })
      }
      const allFiltered = [...allFilteredEvents, ...openCallsArray, ...episodes]
      return allFiltered
    },
    getPodcastEpisodes() {
      let episodes = []
      this.podcasts.forEach(podcast => {
        podcast.podcasts.forEach(episode => {
          episode.url_alias = podcast.url_alias
        })
        episodes = podcast.podcasts.filter(episode => episode.pin_to_start === true)
      })
      return episodes
    },
  },
  mounted() {
    this.handleCalendarDate(new Date().toISOString().slice(0, 10))
  },
  methods: {
    getLongTitle(item) {
      let length = false
      const longTitles = this.plannedEvents.filter(event => event.title.length > 19)
      if (longTitles && longTitles.length) {
        if (item.title.length > 19) {
          length =  true
        } else {
          length = false
        }
      } else {
        length = true
      }
      return length
    },
    async handleCalendarDate(date) {
      const eventsByDate = await this.$axios.get('/api/events?' + query({
        locale: this.$store.getters['locale'],
        populate: [
          'localizations',
          'categories',
          'main_image',
          'event_info',
          'producer',
          'special_tags',
          'subevent.event_info',
          'subevent.producer',
          'subevent.special_tags',
        ],
        filters: {
          $or: [
            {
              event_info: {
                date_from: {
                  $eq: date
                }
              }
            },
            {
              $and: [
                {
                  event_info: {
                    date_from: {
                      $lte: date
                    }
                  }
                },
                {
                  event_info: {
                    date_to: {
                      $gte: date
                    }
                  }
                }
              ]
            }
          ]
        },
        sort: [
          'event_info.date_from:asc'
        ],
      }))

      let singleEvents = []
      let rangeEvents = []
      let subevents = []
      if (eventsByDate.filter(event => !event.subevent?.length).length) {
        singleEvents = eventsByDate.filter(event => !event.subevent?.length)
      }
      if (eventsByDate.filter(event => event.subevent?.length).length) {
        eventsByDate.filter(event => event.subevent?.length).forEach(event => {
          if (
            event.subevent?.filter(subevent => subevent.event_info?.date_from <= date && subevent.event_info?.date_to >= date).length ||
            event.subevent?.filter(subevent => subevent.event_info?.date_from === date).length
          ) {
            event.subevent?.filter(subevent => subevent.event_info?.date_from === date || subevent.event_info?.date_from <= date && subevent.event_info?.date_to >= date).forEach(subevent => {
              subevent.main_image = event.main_image
              subevent.categories = event.categories
              subevent.introtext = event.introtext
              subevent.url_alias = event.url_alias
              
              subevents.push(subevent)
            })
          } else {
            rangeEvents.push(event)
          }
        })
      }

      this.eventsCalendar = [...singleEvents, ...subevents, ...rangeEvents]
    }
  }
}
