
import select from '~/plugins/select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Select',
  props: {
    options: {
      type: [Array, String],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    clear: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    clear() {
      if (this.clear) {
        this.$refs.vSelect.searchEl.blur()
        this.selected = ''
      } return null
    }
  },
  data() {
    return {
      selected: '',
    }
  }
}
