import { render, staticRenderFns } from "./shape-default.vue?vue&type=template&id=d3e87f84&"
import script from "./shape-default.vue?vue&type=script&lang=js&"
export * from "./shape-default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Header.vue').default,Partners: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Partners.vue').default,BigButton: require('/home/runner/work/meetfactory-2/meetfactory-2/components/BigButton.vue').default,Gallery: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Gallery.vue').default})
