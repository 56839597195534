
import serp from '~/mixins/serp'
import locale from '~/mixins/locale'
import localizeUrl from '~/mixins/localizeUrl'
import query from '~/helpers/query-2'

export default {
  name: 'MeetPromoGalleryDetailImage',
  mixins: [serp, locale, localizeUrl],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/promo-photo-galleries?' + query({
      locale: store.getters['locale'],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }
    
    return {
      page: data[0],
      images: data[0].upload_images?.data,
      localization: data[0]?.localizations?.data[0]?.attributes,
      api: process.env.apiUrl,
    }
  },
  data() {
    return {
      arrowsDark: false,
      active: '',
      activeIndex: ''
    }
  },
  mounted() {
    this.setActiveImage()
    if (this.$route.query?.id) {
      this.$refs.splide.go(this.$route.query.id)
    }
  },
  methods: {
    setActiveImage() {
      const activeIndex = this.$refs.splide.index
      this.activeIndex = activeIndex + 1
      this.active = this.images[activeIndex]

      if (this.$refs.galleryImageWrapper.offsetWidth - 100 <= this.$refs.galleryImageImage[activeIndex + 1].offsetWidth) {
        this.arrowsDark = true
      } else {
        this.arrowsDark = false
      }
    }
  }
}
