
import serp from '~/mixins/serp'
import query from '~/helpers/query'

export default {
  name: 'MeetTerms',
  mixins: [serp],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/meet-factory-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
      ],
      filters: {
        key: {
          $eq: 'terms'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }
    
    return {
      page: data[0],
    }
  },
}
