
export default {
  name: 'Gallery',
  props: {
    images: {
      type: [Object, String, Array],
      default: null
    },
    slug: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      index: null,
      api: process.env.apiUrl,
    }
  },
  computed: {
    getPreviews() {
      if (!this.images) {
        return null
      }
      const images = []
      this.images.forEach((image, index) => {
        if ((index + 1) % 5 === 0) {
          images.push(this.api + image?.attributes?.url + '?s=1200x400')
        } else {
          images.push(this.api + image?.attributes?.url + '?s=400x400')
        }
      })
      return images
    },
  }
}
