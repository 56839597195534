
import serp from '~/mixins/serp'
import today from '~/mixins/today'
import getCategory from '~/mixins/getCategory'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'ResidencyStudio',
  mixins: [serp, today, getCategory, getAllImages],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.path.substring(route.path.lastIndexOf('/') + 1)
    const data = await $axios.get('/api/residency-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'residents',
        'events.main_image',
        'events.event_info',
        'events.categories',
        'events.special_tags',
        'residents.image',
        'residents.country',
        'partners.image',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }
    
    return {
      page: data[0],
      paginationPage: 1,
    }
  },
  computed: {
    sortedRelatedResidents() {
      let sortedRelatedResidents = []
      sortedRelatedResidents = this.page.residents?.data
      sortedRelatedResidents = sortedRelatedResidents.filter(resident => resident.attributes?.date_from > this.today)
      sortedRelatedResidents = sortedRelatedResidents.sort((a, b) => {
        return (Date.parse(b.attributes.date_from) > Date.parse(a.attributes.date_from)) ? 1 : (Date.parse(b.attributes.date_from) < Date.parse(a.attributes.date_from)) ? -1 : 0;
      })
      return sortedRelatedResidents
    },
    sortedResidentEvents() {
      let sortedResidentEvents = []
      sortedResidentEvents = this.page.events?.data
      sortedResidentEvents = sortedResidentEvents.filter(event => event.attributes?.event_info.date_from > this.today)
      sortedResidentEvents = sortedResidentEvents.sort(function (a, b) {
        return (Date.parse(b.attributes?.event_info.date_from) > Date.parse(a.attributes?.event_info.date_from)) ? 1 : (Date.parse(b.attributes?.event_info.date_from) < Date.parse(a.attributes?.event_info.date_from)) ? -1 : 0;
      })
      return sortedResidentEvents
    }
  }
}
