
export default {
  name: 'PastCallsList',
  props: {
    pastCalls: {
      type: Array,
      default: null
    }
  },
  computed: {
    getYearsOfPastCalls() {
      let years = []
      let filteredYears = []
      this.pastCalls.forEach(call => {
        let year = call.deadline_date.slice(0, 4)
        years.push(year)
        filteredYears = [...new Set(years)]
      })
      return filteredYears
    },
  }
}
