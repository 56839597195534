
export default {
  name: 'CategoryItem',
  props: {
    title: {
      type: String,
      default: 'title'
    },
    image: {
      type: String,
      default: null
    },
    state: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: ''
    },
    longTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      api: process.env.apiUrl,
      isMobile: false,
      hovered: false
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true
    }
  },
}
