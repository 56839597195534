
import serp from '~/mixins/serp'
import query from '~/helpers/query'

export default {
  name: 'GalleryOpenCalls',
  mixins: [serp],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/gallery-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
      ],
      filters: {
        key: {
          $eq: 'open-calls'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const openCalls = await $axios.get('/api/open-calls?' + query({
      locale: store.getters['locale'],
      filters: {
        $and: [
          {
            $or: [
              {
                category: {
                  key: {
                    $eq: 'gallery'
                  }
                }
              },
              {
                category: {
                  key: {
                    $eq: 'gallery-kostka'
                  }
                }
              },
              {
                category: {
                  key: {
                    $eq: 'gallery-meetfactory'
                  }
                }
              }
            ]
          },
          {
            deadline_date: {
              $gte: new Date().toISOString().slice(0, 10)
            }
          }
        ]
      },
      sort: [
        'deadline_date:desc'
      ]
    }))
    
    return {
      page: data[0],
      openCalls: openCalls,
    }
  },
  data() {
    return {
      isMobile: false
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true
    }
  },
}
