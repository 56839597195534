export default {
  methods: {
    getEventState(tags) {
      let states = []
      tags?.map(tag => {
        if (tag.attributes?.key === 'cancelled') {
          states.push('cancelled')
        }
        if (tag.attributes?.key === 'uncertain-date') {
          states.push('uncertain-date')
        }
        if (tag.attributes?.key === 'new-location') {
          states.push('new-location')
        }
        if (tag.attributes?.key === 'extended') {
          states.push('extended')
        }
        if (tag.attributes?.key === 'moved') {
          states.push('moved')
        }
      })
      return states
    }
  }
}