
import serp from '~/mixins/serp'
import locale from '~/mixins/locale'
import localizeUrl from '~/mixins/localizeUrl'
import query from '~/helpers/query'

export default {
  name: 'PodcastDetail',
  mixins: [serp, locale, localizeUrl],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/podcasts?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'constructor_right.image',
        'constructor_right.upload_files',
        'constructor_right.mf_staff',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'main_image',
        'podcasts.image',
        'podcasts.publish_date',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }
    
    return {
      page: data[0],
      localization: data[0]?.localizations?.data[0]?.attributes,
      episodes: data[0].podcasts
    }
  },
}
