
import serp from '~/mixins/serp'
import query from '~/helpers/query'

export default {
  name: 'MeetContact',
  mixins: [serp],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/site-setting?' + query({
      locale: store.getters['locale'],
      filters: null,
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'staff.mf_staffs'
      ]
    }))
    if(!data){
      redirect('/error')
    }

    return {
      page: data,
      mainStaff: data.staff.filter(section => 
        section.section_title !== 'Provoz a údržba' &&
        section.section_title !== 'Bar' &&
        section.section_title !== 'Ztráty a nálezy'
      ),
      otherStaff: data.staff.filter(section => 
        section.section_title === 'Provoz a údržba' ||
        section.section_title === 'Bar' ||
        section.section_title === 'Ztráty a nálezy'
      )
    }
  }
}
