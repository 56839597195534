
import query from '~/helpers/query'

export default {
  name: 'NewsBar',
  data() {
    return {
      news: '',
      hovered: false
    }
  },
  computed: {
    duplicatedNews() {
      if (this.news?.length) {
        return [...this.news, ...this.news, ...this.news]
      } return
    },
  },
  mounted() {
    this.getNews()
  },
  watch: {
    news() {
      if (this.news?.length) {
        this.$emit('show')
      } return
    },
    '$store.state.locale'() {
      this.getNews()
    }
  },
  methods: {
    async getNews() {
      this.news = await this.$axios.get('/api/news?' + query({
        locale: this.$store.getters['locale'],
        filters: {
          display_as_warning_on_top: {
            $eq: true
          }
        },
        pagination: {
          limit: 1
        }
      }))
    }
  }
}
