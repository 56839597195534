
import format from '~/mixins/format'

export default {
  name: 'EpisodeCard',
  mixins: [format],
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'title'
    },
    introtext: {
      type: String,
      default: null
    },
    publish_date: {
      type: String,
      default: null
    },
    spotify_url: {
      type: String,
      default: ''
    },
    apple_url: {
      type: String,
      default: ''
    },
    breaker_url: {
      type: String,
      default: ''
    },
    google_url: {
      type: String,
      default: ''
    },
    pocketcasts_url: {
      type: String,
      default: ''
    },
    radiopublic_url: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  }
}
