
export default {
  name: 'ContactPerson',
  props:{
    person:{
      type: Object,
      default: null
    }
  },
}
