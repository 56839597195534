
import serp from '~/mixins/serp'
import locale from '~/mixins/locale'
import localizeUrl from '~/mixins/localizeUrl'
import query from '~/helpers/query-2'

export default {
  name: 'MeetPromoGalleryDetail',
  mixins: [serp, locale, localizeUrl],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/promo-photo-galleries?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'upload_images'
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }
    
    return {
      page: data[0],
      images: data[0].upload_images?.data,
      localization: data[0]?.localizations?.data[0]?.attributes,
      slug: slug
    }
  },
}
