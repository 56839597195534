
import format from '~/mixins/format'
import getYears from '~/mixins/getYears'
import locale from '~/mixins/locale'

export default {
  name: 'FilterEvetns',
  mixins: [format, getYears, locale],
  props: {
    years: {
      type: Boolean,
      default: false
    },
    gallery: {
      type: Boolean,
      default: false
    },
    country: {
      type: Boolean,
      default: false
    },
    eventType: {
      type: Boolean,
      default: false
    },
    pressReleaseType: {
      type: Boolean,
      default: false
    },
    dramaturgy: {
      type: Boolean,
      default: false
    },
    calendar: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    },
    future: {
      type: Boolean,
      default: false
    },
    week: {
      type: Boolean,
      default: false
    },
    month: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMobile: false,
      weekFilter: '',
      monthFilter: '',
      currentFilter: '',
      futureFilter: '',
      searchFilter: '',
      yearFilter: '',
      galleryFilter: '',
      countryFilter: '',
      eventTypeFilter: '',
      pressReleaseTypeFilter: '',
      dramaturgyFilter: '',
      showCalendar: false,
      selectedDate: '',
      galleries: [],
      countries: [],
      typesOfEvents: [],
      typesOfPressReleases: [],
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true
      this.showCalendar = true
    }
    if (this.gallery) {
      this.getGalleries()
    }
    if (this.country) {
      this.getCountries()
    }
    if (this.eventType) {
      this.getTypesOfEvents()
    }
    if (this.pressReleaseType) {
      this.getTypesOfPressReleases()
    }
  },
  methods: {
    handleWeekFilter() {
      if (this.weekFilter === 7) {
        this.weekFilter = ''
      } else {
        this.weekFilter = 7
      }
      this.monthFilter = ''
      this.currentFilter = ''
      this.futureFilter = ''
      this.showCalendar = false
      this.selectedDate = ''
      this.searchFilter = ''
      this.yearFilter = ''
      this.galleryFilter = ''
      this.countryFilter = ''
      this.eventTypeFilter = ''
      this.pressReleaseTypeFilter = ''
      this.dramaturgyFilter = ''
      this.$emit('filterRange', this.weekFilter)
    },
    handleMonthFilter() {
      this.weekFilter = ''
      if (this.monthFilter === 30) {
        this.monthFilter = ''
      } else {
        this.monthFilter = 30
      }
      this.currentFilter = ''
      this.futureFilter = ''
      this.showCalendar = false
      this.selectedDate = ''
      this.searchFilter = ''
      this.yearFilter = ''
      this.galleryFilter = ''
      this.countryFilter = ''
      this.eventTypeFilter = ''
      this.pressReleaseTypeFilter = ''
      this.dramaturgyFilter = ''
      this.$emit('filterRange', this.monthFilter)
    },
    handleCurrentFilter() {
      this.weekFilter = ''
      this.monthFilter = ''
      if (this.currentFilter === 'current') {
        this.currentFilter = ''
      } else {
        this.currentFilter = 'current'
      }
      this.futureFilter = ''
      this.showCalendar = false
      this.selectedDate = ''
      this.searchFilter = ''
      this.yearFilter = ''
      this.galleryFilter = ''
      this.countryFilter = ''
      this.eventTypeFilter = ''
      this.pressReleaseTypeFilter = ''
      this.dramaturgyFilter = ''
      this.$emit('filterRange', this.currentFilter)
    },
    handleFutureFilter() {
      this.weekFilter = ''
      this.monthFilter = ''
      this.currentFilter = ''
      if (this.futureFilter === 'future') {
        this.futureFilter = ''
      } else {
        this.futureFilter = 'future'
      }
      this.showCalendar = false
      this.selectedDate = ''
      this.searchFilter = ''
      this.yearFilter = ''
      this.galleryFilter = ''
      this.countryFilter = ''
      this.eventTypeFilter = ''
      this.pressReleaseTypeFilter = ''
      this.dramaturgyFilter = ''
      this.$emit('filterRange', this.futureFilter)
    },
    handleYearFilter(year) {
      this.yearFilter = year
      this.searchFilter = ''
      this.weekFilter = ''
      this.monthFilter = ''
      this.currentFilter = ''
      this.futureFilter = ''
      this.selectedDate= ''
      this.$emit('filterYear', year)
    },
    handleGalleryFilter(gallery) {
      this.galleryFilter = gallery
      this.searchFilter = ''
      this.weekFilter = ''
      this.monthFilter = ''
      this.currentFilter = ''
      this.futureFilter = ''
      this.selectedDate= ''
      this.$emit('filterGallery', gallery)
    },
    handleCountryFilter(country) {
      this.countryFilter = country
      this.searchFilter = ''
      this.weekFilter = ''
      this.monthFilter = ''
      this.currentFilter = ''
      this.futureFilter = ''
      this.selectedDate= ''
      this.$emit('filterCountry', country)
    },
    handleEventTypeFilter(type) {
      this.eventTypeFilter = type
      this.searchFilter = ''
      this.filterSelected = ''
      this.selectedDate= ''
      this.$emit('filterEventType', type)
    },
    handlePressReleaseTypeFilter(type) {
      this.pressReleaseTypeFilter = type
      this.searchFilter = ''
      this.weekFilter = ''
      this.monthFilter = ''
      this.currentFilter = ''
      this.futureFilter = ''
      this.selectedDate= ''
      this.$emit('filterPressReleaseType', type)
    },
    handleDramaturgyFilter(dramaturgy) {
      this.dramaturgyFilter = dramaturgy
      this.searchFilter = ''
      this.weekFilter = ''
      this.monthFilter = ''
      this.currentFilter = ''
      this.futureFilter = ''
      this.selectedDate= ''
      this.$emit('filterDramaturgy', dramaturgy)
    },
    handleDateFilter(date) {
      this.selectedDate = date
      this.showCalendar = false
      this.searchFilter = ''
      this.weekFilter = ''
      this.monthFilter = ''
      this.currentFilter = ''
      this.futureFilter = ''
      this.yearFilter = ''
      this.galleryFilter = ''
      this.countryFilter = ''
      this.eventTypeFilter = ''
      this.pressReleaseTypeFilter = ''
      this.dramaturgyFilter = ''
      this.$emit('filterDate', date)
    },
    handleSearchFilter(search) {
      this.searchFilter = search
      this.weekFilter = ''
      this.monthFilter = ''
      this.currentFilter = ''
      this.futureFilter = ''
      this.selectedDate= ''
      this.yearFilter = ''
      this.galleryFilter = ''
      this.countryFilter = ''
      this.eventTypeFilter = ''
      this.pressReleaseTypeFilter = ''
      this.dramaturgyFilter = ''
      this.$emit('filterSearch', search)
    },
    async getGalleries() {
      const categories = await this.$axios.get('/api/categories')

      let galleries = categories.filter(category => category.key.includes('gallery'))
      let galleryNames = []
      galleries = galleries.forEach(gallery => {
        if (this.locale === 'cs') {
          galleryNames.push(gallery.name)
        } else {
          galleryNames.push(gallery.name_en)
        }
      })
      return this.galleries = galleryNames
    },
    async getCountries() {
      const allCountries = await this.$axios.get('/api/countries')

      let countries = allCountries
      let countryNames = []
      countries.forEach(country => {
        if (this.locale === 'cs') {
          countryNames.push(country.name)
        } else {
          countryNames.push(country.name_en)
        }
      })
      return this.countries = countryNames
    },
    async getTypesOfEvents() {
      const typesOfEvents = await this.$axios.get('/api/type-of-events')

      let types = typesOfEvents
      let typeNames = []
      types.forEach(type => {
        if (this.locale === 'cs') {
          typeNames.push(type.name)
        } else {
          typeNames.push(type.name_en)
        }
      })
      return this.typesOfEvents = typeNames
    },
    async getTypesOfPressReleases() {
      const typesOfPressReleases = await this.$axios.get('/api/type-press-releases')

      let types = typesOfPressReleases
      let typeNames = []
      types.forEach(type => {
        if (this.locale === 'cs') {
          typeNames.push(type.name)
        } else {
          typeNames.push(type.name_en)
        }
      })
      return this.typesOfPressReleases = typeNames
    },
  },
}
