
import serp from '~/mixins/serp'
import today from '~/mixins/today'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'TheatreDirectorDetail',
  mixins: [serp, today, getAllImages], 
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/theater-directors?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'image',
        'country',
        'constructor_right.image',
        'constructor_right.upload_files',
        'constructor_right.mf_staff',
        'events.main_image',
        'events.event_info',
        'events.categories',
        'events.special_tags',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    return {
      page: data[0],
      director: data[0],
      api: process.env.apiUrl,
    }
  },
  computed: {
    sortedDirectorPlays() {
      let sortedDirectorPlays = []
      sortedDirectorPlays = this.director.events?.data
      sortedDirectorPlays = sortedDirectorPlays.filter(event => event.attributes?.event_info.date_from > this.today)
      sortedDirectorPlays = sortedDirectorPlays.sort(function (a, b) {
        return (Date.parse(a.attributes?.event_info.date_from) > Date.parse(b.attributes?.event_info.date_from)) ? 1 : (Date.parse(a.attributes?.event_info.date_from) < Date.parse(b.attributes?.event_info.date_from)) ? -1 : 0;
      })
      return sortedDirectorPlays
    }
  }
}
