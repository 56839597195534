
import serp from '~/mixins/serp'
import isMore from '~/mixins/isMore'
import query from '~/helpers/query'

export default {
  name: 'MeetSummerCinema',
  mixins: [serp, isMore],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/meet-factory-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
      ],
      filters: {
        key: {
          $eq: 'cinema'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const eventsCinema = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            $and: [
              {
                type_of_event: {
                  key: {
                    $eq: 'cinema'
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          },
          {
            $and: [
              {
                type_of_event: {
                  key: {
                    $eq: 'cinema'
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $lte: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                event_info: {
                  date_to: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          }
        ]
      },
      sort: [
        'event_info.date_from:asc'
      ],
      pagination: {
        page: 1,
        pageSize: 6,
      }
    }))
    
    return {
      page: data[0],
      events: eventsCinema,
      paginationPage: 1,
      filters: {
        date: null,
        search: null,
        range: null
      }
    }
  },
  watch:{
    filters:{
      handler(){
        this.events = []
        this.paginationPage = 0
        this.loadEvents()
      },
      deep: true
    }
  },
  methods: {
    async loadEvents() {
      this.paginationPage++

      let filterByDate = 
      this.filters.date ? 
      {
        $or: [
          {
            event_info: {
              date_from: {
                $eq: this.filters.date
              }
            }
          },
          {
            $and: [
              {
                event_info: {
                  date_from: {
                    $lte: this.filters.date
                  }
                }
              },
              {
                event_info: {
                  date_to: {
                    $gte: this.filters.date
                  }
                }
              }
            ]
          }
        ]
      } :
      this.filters.range ? 
      {
        $or: [
          {
            $and: [
              {
                event_info: {
                  date_from: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $lte: new Date(new Date().getTime() + (this.filters.range * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
                  }
                }
              }
            ]
          },
          {
            $or: [
              {
                $and: [
                  {
                    event_info: {
                      date_from: {
                        $lte: new Date(new Date().getTime() + (this.filters.range * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
                      }
                    }
                  },
                  {
                    event_info: {
                      date_to: {
                        $gte: new Date(new Date().getTime() + (this.filters.range * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
                      }
                    }
                  }
                ]
              },
              {
                $and: [
                  {
                    event_info: {
                      date_to: {
                        $gte: new Date().toISOString().slice(0, 10)
                      }
                    }
                  },
                  {
                    event_info: {
                      date_to: {
                        $lte: new Date(new Date().getTime() + (this.filters.range * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
                      }
                    }
                  }
                ]
              }
            ]
          }
        ]
      } : 
      {
        $or: [
          {
            event_info: {
              date_from: {
                $gte: new Date().toISOString().slice(0, 10)
              }
            },
            event_info: {
              date_to: {
                $null: true
              }
            }
          },
          {
            event_info: {
              date_from: {
                $lte: new Date().toISOString().slice(0, 10)
              }
            },
            event_info: {
              date_to: {
                $gte: new Date().toISOString().slice(0, 10)
              }
            },
          }
        ]
      }

      const filterByTitle = this.filters.search ? {
        page_title: {
          $containsi: this.filters.search
        }
      } : null

      const eventsCinema = await this.$axios.get('/api/events?' + query({
        locale: this.$store.getters['locale'],
        filters: {
          $and: [
            {
              type_of_event: {
                key: {
                  $eq: 'cinema'
                }
              }
            },
            {
              ...filterByDate
            },
            {
              ...filterByTitle
            }
          ]
        },
        sort: [
          'event_info.date_from:asc'
        ],
        pagination: {
          page: this.paginationPage,
          pageSize: 6,
        }
      }))
      this.events.push(...eventsCinema)
    },
    handleDateFilter(filteredDate) {
      this.filters.date = filteredDate
      this.filters.range = null
      this.filters.search = null
    },
    handleRangeFilter(filteredRange) {
      this.filters.date = null
      this.filters.range = filteredRange
      this.filters.search = null
    },
    handleSearchFilter(filteredSearch) {
      this.filters.date = null
      this.filters.range = null
      this.filters.search = filteredSearch
    },
  }
}
