export default {
  computed: {
    getAllImages() {
      const galleryImages = this.page?.photo_gallery?.upload_images?.data
      const promo_gallery = this.page?.photo_gallery?.promo_photo_gallery?.data?.attributes?.upload_images?.data
      if (!galleryImages && !promo_gallery) {
        return 
      }
      if (galleryImages && !promo_gallery) {
        return galleryImages
      }
      if (!galleryImages && promo_gallery) {
        return promo_gallery
      }
      return [...galleryImages, ...promo_gallery]
    },
  }
}