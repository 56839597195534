
export default {
  name: 'Footer',
  computed: {
    navigation() {
      if (this.$store.getters['locale'] === 'cs') {
        return this.$store.getters['navigation/footerCS']
      } else {
        return this.$store.getters['navigation/footerEN']
      }
    }
  },
  methods: {
    siteSettings(key) {
      return this.$store.getters['siteSettings'][key]
    },
    getShortenedUrl(item) {
      if (item.uiRouterKey.includes('press')) {
        return { name: this.$getUrl(`press-${item.url_cs}`) }
      }
      if (item.uiRouterKey.includes('partner')) {
        return { name: this.$getUrl(`partner-${item.url_cs}`) }
      }
      if (item.uiRouterKey.includes('kontakt') || item.uiRouterKey.includes('contact')) {
        return { name: this.$getUrl(`contacts-${item.url_cs}`) }
      }
      if (
        item.uiRouterKey.includes('annual') ||
        item.uiRouterKey.includes('report') ||
        item.uiRouterKey.includes('vyrocni') ||
        item.uiRouterKey.includes('zprav')
      ) {
        return { name: this.$getUrl(`annual-report-${item.url_cs}`) }
      }
      return { name: this.$getUrl(`meet-factory-page-${item.related.key || item.url_cs}`) }

    },
  }
}
