
import serp from '~/mixins/serp'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'GalleryConcept',
  mixins: [serp, getAllImages],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/gallery-pages?' + query({
      locale: store.getters['locale'],
       populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images'
      ],
      filters: {
        key: {
          $eq: 'concept'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const galleryPagesImages = await $axios.get('/api/gallery-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'main_image'
      ],
      filters: {
        $or: [
          {
            key: {
              $eq: 'meet'
            }
          },
          {
            key: {
              $eq: 'kostka'
            }
          },
          {
            key: {
              $eq: 'accompanying-programs'
            }
          }
        ]
      }
    }))
    
    return {
      page: data[0],
      galleryMeet: galleryPagesImages.find(page => page.key === 'meet' ),
      galleryKostka: galleryPagesImages.find(page => page.key === 'kostka' ),
    }
  },
}
