
import serp from '~/mixins/serp'
import query from '~/helpers/query'

export default {
  name: 'MeetPress',
  mixins: [serp],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/press?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'contacts',
        'logos_download.upload_logo',
      ],
    }))
    if(!data && !data.length){
      redirect('/error')
    }

    const pressReleases = await $axios.get('/api/press-realeses?' + query({
      locale: store.getters['locale'],
      sort: [
        'publishedAt:desc'
      ],
      pagination: {
        page: 1,
        pageSize: 10,
      }
    }))
    
    return {
      page: data,
      pressReleases: pressReleases,
      logos: data.logos_download,
      contacts: data.contacts,
      paginationPage: 1,
      filters: {
        year: null,
        type: null,
        dramaturgy: null,
        search: null
      }
    }
  },
  watch:{
    filters:{
      handler(){
        this.pressReleases = []
        this.paginationPage = 0
        this.loadPressReleases()
      },
      deep: true
    }
  },
  methods: {
    async loadPressReleases() {
      this.paginationPage++

      const filterByTitle = this.filters.search ? {
        $or: [
          {
            publishedAt: {
              $containsi: this.filters.search
            }
          },
          {
            type_press_release: {
              name: {
                $containsi: this.filters.search
              }
            }
          },
          {
            category: {
              name: {
                $containsi: this.filters.search
              }
            }
          },
        ]
      } : null

      const filterByYear = this.filters.year ? {
        publishedAt: {
          $containsi: this.filters.year
        }
      } : null

      const filterByDramaturgy = this.filters.dramaturgy ? {
        category: {
          $or: [
            {
              name: {
                $eq: this.filters.dramaturgy
              }
            },
            {
              name_en: {
                $eq: this.filters.dramaturgy
              }
            }
          ]
        }
      } : null

      const filterByType = this.filters.type ? {
        type_press_release: {
          $or: [
            {
              name: {
                $eq: this.filters.type
              }
            },
            {
              name_en: {
                $eq: this.filters.type
              }
            }
          ]
        }
      } : null

      const pressReleases = await this.$axios.get('/api/press-realeses?' + query({
        locale: this.$store.getters['locale'],
        filters: {
          $and: [
            {
              ...filterByType
            },
            {
              ...filterByDramaturgy
            },
            {
              ...filterByYear
            },
            {
              ...filterByTitle
            }
          ]
        },
        sort: [
          'publishedAt:desc'
        ],
        pagination: {
          page: this.paginationPage,
          pageSize: 10,
        }
      }))
      this.pressReleases.push(...pressReleases)
    },
    handleYearFilter(filteredYear) {
      this.filters.year = filteredYear
      this.filters.search = null
    },
    handlePressReleaseTypeFilter(filteredType) {
      this.filters.type = filteredType
      this.filters.search = null
    },
    handleDramaturgyFilter(filteredDramaturgy) {
      this.filters.dramaturgy = filteredDramaturgy
      this.filters.search = null
    },
    handleSearchFilter(filteredSearch) {
      this.filters.year = null
      this.filters.type = null
      this.filters.dramaturgy = null
      this.filters.search = filteredSearch
    },
  }
}
