
import format from '~/mixins/format'
import locale from '~/mixins/locale'

export default {
  name: 'EventDetailHeader',
  mixins: [format, locale],
  props: {
    title: {
      type: String,
      default: 'title'
    },
    introtext: {
      type: String,
      default: null
    },
    date_from: {
      type: String,
      default: null
    },
    date_to: {
      type: String,
      default: null
    },
    time_from: {
      type: String,
      default: null
    },
    time_to: {
      type: String,
      default: null
    },
    language: {
      type: String,
      default: null
    },
    occurances: {
      type: Array,
      default: null
    },
    producer: {
      type: Object,
      default: null
    },
    tags: {
      type: [Array, Object],
      default: null
    },
    selected: {
      type: Object,
      default: null
    },
    theater: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      occuranceSelected: this.selected,
    }
  },
  computed: {
    showDate() {
      if (this.theater) {
        if (this.occuranceSelected !== '' && !this.occuranceSelected.subevent?.length) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
}
