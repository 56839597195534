
import format from '~/mixins/format'
import locale from '~/mixins/locale'

export default {
  name: 'PressReleaseCard',
  mixins: [format, locale],
  props: {
    publish_date: {
      type: String,
      default: null
    },
    type_of_press_release: {
      type: [String, Object],
      default: null
    },
    category: {
      type: [String, Object],
      default: null
    },
    title: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: ''
    },
  },
  computed: {
    getTypeOfPressRelease() {
      let type
      this.locale === 'cs' ?
        type = this.type_of_press_release.name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'') :
        type = this.type_of_press_release.name_en.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')
      return type
    }
  }
}
