import { render, staticRenderFns } from "./past-calls.vue?vue&type=template&id=6e309a57&"
import script from "./past-calls.vue?vue&type=script&lang=js&"
export * from "./past-calls.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Header.vue').default,ConstructorLayout: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Constructor/Layout.vue').default,PastCallsList: require('/home/runner/work/meetfactory-2/meetfactory-2/components/PastCallsList.vue').default})
