
export default {
  name: 'ConstructorFile',
  props:{
    upload_logos:{
      type: String,
      default: null
    },
    logo_label:{
      type: String,
      default: null
    },
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  }
}
