

export default {
  name: 'Menu',
  data() {
    return {
      active: '',
      showMenu: false,
      animation: true
    }
  },
  watch: {
    // showMenu() {
    //   if (this.showMenu) {
    //     document.documentElement.style = "overflow-y: hidden"
    //   } else {
    //     document.documentElement.style  = "overflow-y: auto"
    //   }
    // },
    // '$route.name'() {
    //   this.showMenu = false
    // },
    active() {
      this.animation = false
      setInterval(() => {
        this.animation = true
      }, 400)
    }
  },
  computed: {
    navigation() {
      if (this.$store.getters['locale'] === 'cs') {
        return this.$store.getters['navigation/mainCS']
      } else {
        return this.$store.getters['navigation/mainEN']
      }
    }
  },
  methods: {
    getCorrectUrl(item) {
      if(item.uiRouterKey.includes('shape') || item.uiRouterKey.includes('synapse')) {
        if (item.uiRouterKey.includes('shape')) {
          return { name: this.$getUrl(`shape-page-${item.related.key}`) }
        }
        if (item.uiRouterKey.includes('synapse')) {
          return { name: this.$getUrl(`synapse-page-${item.related.key}`) }
        }
      } else {
        return { path: item.path }
      } 
    },
    getShortenedUrl(item) {
      if (item.uiRouterKey.includes('press')) {
        return { name: this.$getUrl(`press-${item.url_cs}`) }
      }
      if (item.uiRouterKey.includes('partner')) {
        return { name: this.$getUrl(`partner-${item.url_cs}`) }
      }
      if (item.uiRouterKey.includes('kontakt') || item.uiRouterKey.includes('contact')) {
        return { name: this.$getUrl(`contacts-${item.url_cs}`) }
      }
      if (
        item.uiRouterKey.includes('annual') ||
        item.uiRouterKey.includes('report') ||
        item.uiRouterKey.includes('vyrocni') ||
        item.uiRouterKey.includes('zprav')
      ) {
        return { name: this.$getUrl(`annual-report-${item.url_cs}`) }
      }
      return { name: this.$getUrl(`meet-factory-page-${item.related.key || item.url_cs}`) }

    },
    handleOpenMenu(item) {
      this.showMenu = true
      this.active = item
    },
    handleSearch(search) {
      // this.$emit('search', search)
      this.$router.push({
        name: this.$getUrl('search'),
        params: {
          search: search
        }
      })
      this.showMenu = false
    }
  }
}
