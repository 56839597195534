
import locale from '~/mixins/locale'
import format from '~/mixins/format'

export default {
  name: 'MobileOpenCallHeaderDetail',
  mixins: [format, locale],
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    broadcast: {
      type: String,
      default: null
    },
    deadline: {
      type: String,
      default: null
    },
    results: {
      type: String,
      default: null
    },
    category: {
      type: Object,
      default: null
    },
    url: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  }
}
