
import serp from '~/mixins/serp'
import query from '~/helpers/query'
import today from '~/mixins/today'
import localizeUrl from '~/mixins/localizeUrl'
import locale from '~/mixins/locale'

export default {
  name: 'ResidentDetail',
  mixins: [serp, today, localizeUrl, locale],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/residents?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'countries',
        'localizations',
        'image',
        'country',
        'constructor_right.image',
        'constructor_right.upload_files',
        'constructor_right.mf_staff',
        'resident_events.main_image',
        'resident_events.event_info',
        'resident_events.categories',
        'resident_events.special_tags',
        'partners.partner_logo',
        'upload_images',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    if (data && data?.length) {
      data[0].page_title = data[0].full_name
    }

    return {
      page: data[0],
      resident: data[0],
      localization: data[0]?.localizations?.data[0]?.attributes,
      api: process.env.apiUrl,
    }
  },
  computed: {
    sortedResidentEvents() {
      let sortedResidentEvents = []
      sortedResidentEvents = this.resident.resident_events?.data
      sortedResidentEvents = sortedResidentEvents.filter(event => event.attributes?.event_info.date_from > this.today)
      sortedResidentEvents = sortedResidentEvents.sort(function (a, b) {
        return (Date.parse(a.attributes?.event_info.date_from) > Date.parse(b.attributes?.event_info.date_from)) ? 1 : (Date.parse(a.attributes?.event_info.date_from) < Date.parse(b.attributes?.event_info.date_from)) ? -1 : 0;
      })
      return sortedResidentEvents
    }
  },
  data() {
    return {
      isMobile: false
    }
  },
  mounted() {
    console.log(this.page)
    if (this.$isMobile()) {
      this.isMobile = true
    }
  },
}
