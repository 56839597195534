
export default {
  name: 'SocialLinks',
  props: {
    facebook_url: {
      type: String,
      default: ''
    },
    instagram_url: {
      type: String,
      default: ''
    },
    twitter_url: {
      type: String,
      default: ''
    },
    youtube_url: {
      type: String,
      default: ''
    },
    spotify_url: {
      type: String,
      default: ''
    },
  }
}
