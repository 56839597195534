
export default {
    name: 'Button',
    props: {
        type: {
            type: String,
            default: 'primary'
        },
        label: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        }
    }
}
