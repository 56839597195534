

export default {
  name: 'PodcastCard',
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'title'
    },
    introtext: {
      type: String,
      default: null
    },
    url: {
      type: Object,
      default: ''
    },
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  }
}
