import { render, staticRenderFns } from "./archive.vue?vue&type=template&id=ffffe1fc&"
import script from "./archive.vue?vue&type=script&lang=js&"
export * from "./archive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Header.vue').default,BigButton: require('/home/runner/work/meetfactory-2/meetfactory-2/components/BigButton.vue').default,Heading: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Heading.vue').default,EventCard: require('/home/runner/work/meetfactory-2/meetfactory-2/components/EventCard.vue').default,Gallery: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Gallery.vue').default})
