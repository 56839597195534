
import serp from '~/mixins/serp'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'ShapeEvents',
  mixins: [serp, getAllImages],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/shape-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'partners.partner_logo',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        key: {
          $eq: 'events'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const eventsShape = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      populate: [
        'main_image',
        'categories',
        'event_info',
        'special_tags',
        'subevent.event_info',
      ],
      filters: {
        $and: [
          {
            categories: {
              key: {
                $eq: 'shape'
              }
            }
          },
          {
            event_info: {
              date_from: {
                $gte: new Date().toISOString().slice(0, 10)
              }
            }
          }
        ]
      },
      sort: [
        'event_info.date_from:asc'
      ],
    }))
    
    return {
      page: data[0],
      events: eventsShape,
    }
  },
}
