export default {
  methods: {
    getCategory(event) {
      if (event) {
        let category
        if (event?.categories && event.categories.data.length) {
          category = event?.categories?.data[0]?.attributes?.key

          if (category === 'gallery-meetfactory' || category === 'gallery-kostka') {
            category = 'gallery'
          }
  
          if (category === 'meetfactory') {
            return 'meet-factory-page-more-events-detail'
          }
          return category + '-page-program-detail'
        } else if (event?.categories && event.categories.data.length === 0) {
          console.log('trigger')
          return 'meet-factory-page-more-events-detail'
        } else if (event?.category) {
          category = event?.category?.data?.attributes?.key

          if (category === 'gallery-meetfactory' || category === 'gallery-kostka') {
            category = 'gallery'
          }
  
          if (category === 'meetfactory') {
            return 'meet-factory-page-open-calls-detail'
          }
          return category + '-page-open-calls-detail'
        } else {
          return 'meet-factory-page-podcasts-detail'
        } 
      }
    }
  }
}