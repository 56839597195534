import { render, staticRenderFns } from "./podcast-detail.vue?vue&type=template&id=5383dea0&"
import script from "./podcast-detail.vue?vue&type=script&lang=js&"
export * from "./podcast-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Header.vue').default,Heading: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Heading.vue').default,EpisodeCard: require('/home/runner/work/meetfactory-2/meetfactory-2/components/EpisodeCard.vue').default,BigButton: require('/home/runner/work/meetfactory-2/meetfactory-2/components/BigButton.vue').default})
