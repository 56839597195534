
export default {
  name: 'Partners',
  props: {
    partners: {
      type: Array,
      default: null
    },
    span: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  },
}
