
import format from '~/mixins/format'

export default {
  name: 'NewsCard',
  mixins: [format],
  props: {
    title: {
      type: String,
      default: null
    },
    publish_date: {
      type: String,
      default: null
    },
    introtext: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: ''
    }
  }
}
