export default {
  watch: {
    locale() {
      if(this.localization && this.localization.url_alias) {
        const name = this.$route.name.replace("en--", "")

        this.$router.replace({
          name: this.$getUrl(name),
          params: { 
            slug: this.localization.url_alias
          }
        }).catch()
      } else {
        const name = this.$route.name.replace("-detail", "")

        this.$router.replace({
          name: this.$getUrl(name),
        }).catch()
      }
    }
  },
}