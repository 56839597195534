
import serp from '~/mixins/serp'
import today from '~/mixins/today'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'SynapseIndex',
  mixins: [serp, today, getAllImages],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/synapse-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'partners.image',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        key: {
          $eq: 'program'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const eventsSynapse = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      populate: [
        'main_image',
        'categories',
        'event_info',
        'special_tags',
        'subevent.event_info',
        'subevent.special_tags',
      ],
      filters: {
        $and: [
          {
            categories: {
              key: {
                $eq: 'synapse'
              }
            }
          },
          {
            event_info: {
              date_from: {
                $gte: new Date().toISOString().slice(0, 10)
              }
            }
          }
        ]
      },
      sort: [
        'event_info.date_from:asc'
      ],
    }))
    
    return {
      page: data[0],
      events: eventsSynapse,
    }
  },
  computed: {
    getAllEvents() {
      let allEvents = []
      if (this.events.filter(play => play.subevent.length <= 0)[0] != null) {
        allEvents.push(this.events.filter(play => play.subevent.length <= 0)[0])
      }
      if (this.events.filter(event => event.subevent.length > 0 != null)) {
        this.events.filter(event => event.subevent.length > 0).forEach(event => {
          event.subevent.forEach(occurance => {
            occurance.main_image = event.main_image
            occurance.categories = event.categories
            occurance.introtext = event.introtext
            occurance.url_alias = event.url_alias
            allEvents.push(occurance)
          })
        })
      }
      allEvents = allEvents.filter(event => event.event_info.date_from > this.today)
      allEvents = allEvents.sort(function (a, b) {
        return (Date.parse(a.event_info.date_from) > Date.parse(b.event_info.date_from)) ? 1 : (Date.parse(a.event_info.date_from) < Date.parse(b.event_info.date_from)) ? -1 : 0;
      })
      return allEvents
    },
  },
}
