
import getCategory from '~/mixins/getCategory'
import query from '~/helpers/query'
import locale from '~/mixins/locale'

export default {
  name: 'SearchResult',
  mixins: [getCategory, locale],
  async asyncData({ $axios, route, store }) {
    const keyword = route.params.search

    const events = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
          {
            introtext: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const educationalPrograms = await $axios.get('/api/educational-programs-pages?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
          {
            introtext: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const news = await $axios.get('/api/news?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
          {
            introtext: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const openCallsGallery = await $axios.get('/api/open-calls?' + query({
      locale: store.getters['locale'],
      filters: {
        $and: [
          {
            $or: [
              {
                page_title: {
                  $containsi: keyword
                }
              },
              {
                introtext: {
                  $containsi: keyword
                }
              },
            ]
          },
          {
            $or: [
              {
                category: {
                  key: {
                    $eq: 'gallery'
                  }
                }
              },
              {
                category: {
                  key: {
                    $eq: 'gallery-kostka'
                  }
                }
              },
              {
                category: {
                  key: {
                    $eq: 'gallery-meetfactory'
                  }
                }
              }
            ]
          }
        ]
      }
    }))

    const openCallsResidency = await $axios.get('/api/open-calls?' + query({
      locale: store.getters['locale'],
      filters: {
        $and: [
          {
            $or: [
              {
                page_title: {
                  $containsi: keyword
                }
              },
              {
                introtext: {
                  $containsi: keyword
                }
              },
            ]
          },
          {
            category: {
              key: {
                $eq: 'residency'
              }
            }
          }
        ]
      }
    }))

    const podcasts = await $axios.get('/api/podcasts?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
          {
            introtext: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const pressReleases = await $axios.get('/api/press-realeses?' + query({
      locale: store.getters['locale'],
      filters: {
        page_title: {
          $containsi: keyword
        }
      }
    }))

    const residents = await $axios.get('/api/residents?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            full_name: {
              $containsi: keyword
            }
          },
          {
            introtext: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const pagesGallery = await $axios.get('/api/gallery-pages?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const pagesResidency = await $axios.get('/api/residency-pages?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const pagesMusic = await $axios.get('/api/music-pages?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const pagesTheater = await $axios.get('/api/theater-pages?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const pagesMeetfactory = await $axios.get('/api/meet-factory-pages?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            page_title: {
              $containsi: keyword
            }
          },
        ]
      }
    }))

    const pageAnnualReports = await $axios.get('/api/annual-report?' + query({
      locale: store.getters['locale'],
      filters: null,
    }))

    const pageContact = await $axios.get('/api/site-setting?' + query({
      locale: store.getters['locale'],
      filters: null,
    }))

    const pagePartners = await $axios.get('/api/partner?' + query({
      locale: store.getters['locale'],
      filters: null,
    }))

    const pagePress = await $axios.get('/api/press?' + query({
      locale: store.getters['locale'],
      filters: null,
    }))
    
    return {
      keyword: keyword,
      events: events,
      educationalPrograms: educationalPrograms,
      news: news,
      openCallsGallery: openCallsGallery,
      openCallsResidency: openCallsResidency,
      podcasts: podcasts,
      pressReleases: pressReleases,
      residents: residents,
      pagesGallery: pagesGallery,
      pagesResidency: pagesResidency,
      pagesMusic: pagesMusic,
      pagesTheater: pagesTheater,
      pagesMeetfactory: pagesMeetfactory,
      pageAnnualReports: pageAnnualReports,
      pageContact: pageContact,
      pagePartners: pagePartners,
      pagePress: pagePress,
      numberOfEventResults: 0,
      numberOfOtherResults: [
        ...educationalPrograms,
        ...news,
        ...openCallsGallery,
        ...podcasts,
        ...pressReleases,
        ...residents,
        ...pagesGallery,
        ...pagesResidency,
        ...pagesMusic,
        ...pagesTheater,
        ...pagesMeetfactory,
      ].length,
      semiTotal: 0,
    }
  },
  head() {
    return {
      title: (this.$store.getters['locale'] === 'cs' ? 'Výsledky hledání' : 'Search results for') + ' ' + (this.keyword) +
      " | "
      + (this.$store.getters.siteSettings?.serp?.serp_title ? this.$store.getters.siteSettings?.serp?.serp_title : 'MeetFactory'),
    }
  },
  computed: {
    getTotalNumberOfResults() {
      let total = 0
      if (this.pageAnnualReports?.page_title?.toLowerCase().includes(this.keyword)) {
        total++
      }
      if (this.pageContact?.page_title?.toLowerCase().includes(this.keyword)) {
        total++
      }
      if (this.pagePartners?.page_title?.toLowerCase().includes(this.keyword)) {
        total++
      }
      if (this.pagePress?.page_title?.toLowerCase().includes(this.keyword)) {
        total++
      }
      this.semiTotal = total + this.numberOfOtherResults
      return total + this.numberOfEventResults + this.numberOfOtherResults
    },
    getCategoriesOfResults() {
      let categories = []
      let filteredCategories = []
      this.events.forEach(result => {
        result.categories?.data?.forEach(category => {
          categories.push(category.attributes)
        })
        if (categories && categories.length) {
          this.numberOfEventResults = categories.length
        }
        filteredCategories = [...new Map(categories.map(category => [category.key, category])).values()]
      })
      return filteredCategories
    }
  },
}
