// export default {
//   head() {
//     return this.$seo.generate(
//       this.page,
//       this.$route.path,
//     );
//   },
// };

export default {
  head() {
    return {
      title: (this.page?.serp?.serp_title ? this.page?.serp?.serp_title : this.page?.page_title) +
      " | "
      + (this.$store.getters.siteSettings?.serp?.serp_title ? this.$store.getters.siteSettings?.serp?.serp_title : 'MeetFactory'),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.page?.serp?.serp_description_meta,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.page?.social_sharing?.social_title,
        },
        {
          property: "twitter:title",
          content: this.page?.social_sharing?.social_title,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.page?.serp?.serp_title ? this.page?.serp?.serp_title : this.page?.page_title,
        },
        {
          property: "twitter:title",
          content: this.page?.serp?.serp_title ? this.page?.serp?.serp_title : this.page?.page_title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.page?.social_sharing?.social_description ? this.page?.social_sharing?.social_description : this.page?.introtext,
        },
        {
          property: "twitter:description",
          content: this.page?.social_sharing?.social_description,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: 'https://new.meetfactory.cz' + this.$route.path,
        },
        {
          hid: "twitter:url",
          property: "twitter:url",
          content: 'https://new.meetfactory.cz' + this.$route.path,
        },
        {
          hid: "og:type",
          property: "og:type",
          content: 'article',
        },
        {
          hid: "og:image",
          property: "og:image",
          content: process.env.apiUrl + this.page?.social_sharing?.social_image?.data?.attributes?.url ? process.env.apiUrl + this.page?.social_sharing?.social_image?.data?.attributes?.url : process.env.apiUrl + this.$store.getters.siteSettings?.social_sharing_default_img?.data?.attributes?.url,
        },
        {
          property: "twitter:image",
          content: process.env.apiUrl + this.page?.social_sharing?.social_image.data?.attributes?.url ? process.env.apiUrl + this.page?.social_sharing?.social_image?.data?.attributes?.url : process.env.apiUrl + this.$store.getters.siteSettings?.social_sharing_default_img?.data?.attributes?.url,
        },
        {
          hid: "t-type",
          name: "twitter:card",
          content: "summary_large_image",
        },
      ]
    }
  }
}

