
import format from '~/mixins/format'

export default {
  name: 'OpenCallDetailHeader',
  mixins: [format],
  props: {
    title: {
      type: String,
      default: null
    },
    introtext: {
      type: String,
      default: null
    },
    broadcast: {
      type: String,
      default: null
    },
    deadline: {
      type: String,
      default: null
    },
    results: {
      type: String,
      default: null
    }
  }
}
