import { render, staticRenderFns } from "./press-release-detail.vue?vue&type=template&id=21df60b8&"
import script from "./press-release-detail.vue?vue&type=script&lang=js&"
export * from "./press-release-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Heading.vue').default,ContactPerson: require('/home/runner/work/meetfactory-2/meetfactory-2/components/ContactPerson.vue').default,SocialLinks: require('/home/runner/work/meetfactory-2/meetfactory-2/components/SocialLinks.vue').default,ConstructorLayout: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Constructor/Layout.vue').default})
