import { unwrapCollection } from '../helpers/strapi'

export default ({ $axios }) => {
  $axios.onResponse(res => {
    // Transform any and all data coming from strapi to simpler formats
    if (res.config.url.startsWith('/api/')) {
      return unwrapCollection(res.data)
    }

    return res
  })
}
