import Vue from 'vue'
import dictionary from '~/dictionary'


export default ({ store }) => {
  Vue.filter('translate', (key) => {
    if (!(key in dictionary)) {
      throw new Error(`Invalid dictionary key "${key}". Not found`)
    }

    return dictionary[key][store.state.locale]
  })
}
