
export const isCollectionItem = (obj) => (typeof obj === 'object' && obj !== null) && 'id' in obj && 'attributes' in obj
export const isCollection = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return false
  }

  if (Array.isArray(obj.data)) {
    if (obj.data.length === 0) {
      return true
    }

    return isCollectionItem(obj.data[0])
  }

  if (!!obj.data && typeof obj.data === 'object') {
    return isCollectionItem(obj.data)
  }

  return false
}

export const unwrapCollectionItem = (item, meta) => ({ id: item.id, ...item.attributes, meta })

/**
 * 
 * @param {Object} data Strapi's response data - const { [[data]] } = api.fetch(...) )
 */
export const unwrapCollection = (data) => {
  if (!isCollection(data)) {
    return data
  }

  if (Array.isArray(data.data)) {
    return data.data.map(item => {
      if (isCollectionItem(item)) {
        return unwrapCollectionItem(item, data.meta?.pagination)
      }

      return unwrapCollection(item)
    })
  }

  const collection = unwrapCollectionItem(data.data)

  Object.entries(collection).forEach(([key, value]) => {
    collection[key] = unwrapCollection(value)
  })

  return collection
}
