
import serp from '~/mixins/serp'
import query from '~/helpers/query-2'

export default {
  name: 'MeetPromoGallery',
  mixins: [serp],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/meet-factory-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
      ],
      filters: {
        key: {
          $eq: 'promo-photo'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const promoGalleries = await $axios.get('/api/promo-photo-galleries?' + query({
      locale: store.getters['locale'],
      populate: [
        'main_image',
        'upload_images'
      ],
      sort: [
        'date_from:desc'
      ],
      pagination: {
        page: 1,
        pageSize: 3,
      }
    }))
    
    return {
      page: data[0],
      promoGalleries: promoGalleries,
      paginationPage: 1,
      filters: {
        year: null,
        search: null,
      }
    }
  },
  watch:{
    filters:{
      handler(){
        this.promoGalleries = []
        this.paginationPage = 0
        this.loadPromoGalleries()
      },
      deep: true
    }
  },
  methods: {
    async loadPromoGalleries() {
      this.paginationPage++

      const filterByYear = this.filters.year ? {
        date_from: {
          $containsi: this.filters.year
        }
      } : null

      const filterByTitle = this.filters.search ? {
        page_title: {
          $containsi: this.filters.search
        }
      } : null

      const promoGalleries = await this.$axios.get('/api/promo-photo-galleries?' + query({
        locale: this.$store.getters['locale'],
        populate: [
          'main_image',
          'upload_images'
        ],
        filters: {
          $and: [
            {
              ...filterByYear
            },
            {
              ...filterByTitle
            }
          ]
        },
        sort: [
          'date_from:desc'
        ],
        pagination: {
          page: this.paginationPage,
          pageSize: 3,
        }
      }))
      this.promoGalleries.push(...promoGalleries)
    },
    handleYearFilter(filteredYear) {
      this.filters.year = filteredYear
      this.filters.search = null
    },
    handleSearchFilter(filteredSearch) {
      this.filters.year = null
      this.filters.search = filteredSearch
    },
  }
}
