export default {
  computed: {
    getYears() {
      const max =  new Date().getFullYear()
      const min = max - 15
      let years = []

      for (let year = max; year >= min; year--) {
        years.push(year)
      }
      return years
    }
  }
}