
import serp from '~/mixins/serp'
import isMore from '~/mixins/isMore'
import getCategory from '~/mixins/getCategory'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'GalleryPage',
  mixins: [serp, isMore, getCategory, getAllImages],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.path.substring(route.path.lastIndexOf('/') + 1)
    const data = await $axios.get('/api/gallery-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right',
        'constructor_right.image',
        'constructor_right.upload_files',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    let category = null
    data[0].key === 'meet' ? category = 'gallery-meetfactory' : null
    data[0].key === 'kostka' ? category = 'gallery-kostka' : null

    // if (data[0].key !== 'kostka' && data[0].key !== 'meet') {
    //   category = null
    // }

    const eventsGallery = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      filters: {
        $or: [
          {
            $and: [
              {
              categories: {
                  key: {
                    $eq: category
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          },
          {
            $and: [
              {
              categories: {
                  key: {
                    $eq: category
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $lte: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                event_info: {
                  date_to: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          }
        ]
      },
      sort: [
        'event_info.date_from:asc'
      ],
      pagination: {
        page: 1,
        pageSize: 6,
      }
    }))
    
    return {
      page: data[0],
      events: eventsGallery,
      paginationPage: 1,
      filters: {
        year: null,
        search: null,
        range: null
      }
    }
  },
  computed: {
    getGalleryCategory() {
      if (this.page?.key === 'meet' || this.page?.key === 'kostka') {
        return true
      } else {
        return false
      }
    }
  },
  watch:{
    filters:{
      handler(){
        this.events = []
        this.paginationPage = 0
        this.loadEvents()
      },
      deep: true
    }
  },
  methods: {
    async loadEvents() {
      this.paginationPage++

      let filterByRange = 
      this.filters.range === 'current' ? 
      {
        event_info: {
          date_to: {
            $gte: new Date().toISOString().slice(0, 10)
          },
          date_from: {
            $lte: new Date().toISOString().slice(0, 10)
          }
        }
      } : 
      this.filters.range === 'future' ? 
      {
        event_info: {
          date_from: {
            $gt: new Date().toISOString().slice(0, 10)
          }
        }
      } : 
      this.filters.year ? {
        $or: [
          {
            event_info: {
              date_from: {
                $containsi: this.filters.year
              }
            }
          },
          {
            event_info: {
              date_to: {
                $containsi: this.filters.year
              }
            }
          }
        ]
      } : {
        $or: [
          {
            event_info: {
              date_from: {
                $gte: new Date().toISOString().slice(0, 10)
              }
            }
          },
          {
            $and: [
              {
                event_info: {
                  date_from: {
                    $lte: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                event_info: {
                  date_to: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          }
        ]
      }

      const filterByTitle = this.filters.search ? {
        page_title: {
          $containsi: this.filters.search
        }
      } : null

      let category = null
      this.page.key === 'meet' ? category = 'gallery-meetfactory' : null
      this.page.key === 'kostka' ? category = 'gallery-kostka' : null

      const eventsGallery = await this.$axios.get('/api/events?' + query({
        locale: this.$store.getters['locale'],
        filters: {
          $and: [
            {
            categories: {
                key: {
                  $eq: category
                }
              }
            },
            {
              ...filterByRange
            },
            {
              ...filterByTitle
            }
          ]
        },
        sort: [
          'event_info.date_from:asc'
        ],
        pagination: {
          page: this.paginationPage,
          pageSize: 6,
        }
      }))
      this.events.push(...eventsGallery)
    },
    handleYearFilter(filteredYear) {
      this.filters.year = filteredYear
      this.filters.range = null
      this.filters.search = null
    },
    handleRangeFilter(filteredRange) {
      this.filters.year = null
      this.filters.range = filteredRange
      this.filters.search = null
    },
    handleSearchFilter(filteredSearch) {
      this.filters.year = null
      this.filters.range = null
      this.filters.search = filteredSearch
    },
  }
}
