
import locale from '~/mixins/locale'
import getEventState from '~/mixins/getEventState'
import getCategory from '~/mixins/getCategory'
import format from '~/mixins/format'

export default {
  name: 'HomepageHeroSection',
  mixins: [format, locale, getCategory, getEventState],
  props: {
    eventsCurrent: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedEvent: this.eventsCurrent[0],
      index: this.eventsCurrent.indexOf(this.eventsCurrent[0]),
      interval: '',
      api: process.env.apiUrl,
      isMobile: false,
      hovered: false,
    }
  },
  methods: {
    showDate(event) {
      if (event.categories?.data[0]?.attributes?.key === 'theater') {
        if (event.subevent?.length) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true
    }
    this.interval = setInterval(() => {
      if (!this.hovered) {
        if (this.index < this.eventsCurrent.length) {
          this.selectedEvent = this.eventsCurrent[this.index]
        }
        this.index = this.eventsCurrent.length > this.index + 1 ? this.index + 1 : 0
      } return null
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
