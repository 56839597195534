
const Locales = ['cs', 'en']

export const state = () => ({
  locale: Locales[0],
  siteSettings: null,
})

export const mutations = {
  setLocale(state, locale) { 
    state.locale = locale
  },
  setSiteSettings(state, settings) {
    state.siteSettings = { ...settings }
  },
}

export const getters = {
  locale: (state) => state.locale,
  siteSettings: (state) => state.siteSettings,
}

export const actions = {
  cycleLocale({ commit, dispatch, state }) {
    let index = Locales.indexOf(state.locale) + 1

    // Cycle back to beginning if we're currently on last locale
    if (index >= Locales.length) {
      index = 0
    }

    commit('setLocale', Locales[index])
    this.$cookies.set('lang', Locales[index])
    dispatch('updateSiteSettingsLocale', null, { root: true })
  },
  async nuxtServerInit ({ commit, dispatch }, { route, $cookies }) {
    
    if (route.name?.includes('en--')) {
      $cookies.set('lang', 'en')
      commit('setLocale', 'en')
    }

    if ($cookies.get('lang') === 'en' && !route.name?.includes('en--')) {
      $cookies.set('lang', 'cs')
      commit('setLocale', 'cs')
    }

    await dispatch('navigation/fetchNavigation')
    await dispatch('updateSiteSettingsLocale', null, { root: true })
  },
  async updateSiteSettingsLocale ({ commit }) {
    const apiUrl = process.env.apiUrl
    const data = await this.$axios.get('/api/site-setting' + '?locale=' + this.state.locale)
    commit('setSiteSettings', data)
  }
}
