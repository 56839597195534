export const BigButton = () => import('../../components/BigButton.vue' /* webpackChunkName: "components/big-button" */).then(c => wrapFunctional(c.default || c))
export const BigLink = () => import('../../components/BigLink.vue' /* webpackChunkName: "components/big-link" */).then(c => wrapFunctional(c.default || c))
export const Burger = () => import('../../components/Burger.vue' /* webpackChunkName: "components/burger" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonFilter = () => import('../../components/ButtonFilter.vue' /* webpackChunkName: "components/button-filter" */).then(c => wrapFunctional(c.default || c))
export const ButtonLink = () => import('../../components/ButtonLink.vue' /* webpackChunkName: "components/button-link" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const CalendarItem = () => import('../../components/CalendarItem.vue' /* webpackChunkName: "components/calendar-item" */).then(c => wrapFunctional(c.default || c))
export const CategoryItem = () => import('../../components/CategoryItem.vue' /* webpackChunkName: "components/category-item" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const ContactPerson = () => import('../../components/ContactPerson.vue' /* webpackChunkName: "components/contact-person" */).then(c => wrapFunctional(c.default || c))
export const Devider = () => import('../../components/Devider.vue' /* webpackChunkName: "components/devider" */).then(c => wrapFunctional(c.default || c))
export const DownloadLogo = () => import('../../components/DownloadLogo.vue' /* webpackChunkName: "components/download-logo" */).then(c => wrapFunctional(c.default || c))
export const EpisodeCard = () => import('../../components/EpisodeCard.vue' /* webpackChunkName: "components/episode-card" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventDetailHeader = () => import('../../components/EventDetailHeader.vue' /* webpackChunkName: "components/event-detail-header" */).then(c => wrapFunctional(c.default || c))
export const FilterEvents = () => import('../../components/FilterEvents.vue' /* webpackChunkName: "components/filter-events" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchResult = () => import('../../components/GlobalSearchResult.vue' /* webpackChunkName: "components/global-search-result" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const HomepageHeroSection = () => import('../../components/HomepageHeroSection.vue' /* webpackChunkName: "components/homepage-hero-section" */).then(c => wrapFunctional(c.default || c))
export const ImageLink = () => import('../../components/ImageLink.vue' /* webpackChunkName: "components/image-link" */).then(c => wrapFunctional(c.default || c))
export const InformationSection = () => import('../../components/InformationSection.vue' /* webpackChunkName: "components/information-section" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const JobOfferCard = () => import('../../components/JobOfferCard.vue' /* webpackChunkName: "components/job-offer-card" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const MenuMobile = () => import('../../components/MenuMobile.vue' /* webpackChunkName: "components/menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const MobileHomepageHeroSection = () => import('../../components/MobileHomepageHeroSection.vue' /* webpackChunkName: "components/mobile-homepage-hero-section" */).then(c => wrapFunctional(c.default || c))
export const MobileOpenCallHeaderDetail = () => import('../../components/MobileOpenCallHeaderDetail.vue' /* webpackChunkName: "components/mobile-open-call-header-detail" */).then(c => wrapFunctional(c.default || c))
export const MobileResidentHeaderDetail = () => import('../../components/MobileResidentHeaderDetail.vue' /* webpackChunkName: "components/mobile-resident-header-detail" */).then(c => wrapFunctional(c.default || c))
export const Nav = () => import('../../components/Nav.vue' /* webpackChunkName: "components/nav" */).then(c => wrapFunctional(c.default || c))
export const NavMobile = () => import('../../components/NavMobile.vue' /* webpackChunkName: "components/nav-mobile" */).then(c => wrapFunctional(c.default || c))
export const NewsBar = () => import('../../components/NewsBar.vue' /* webpackChunkName: "components/news-bar" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const NotFound = () => import('../../components/NotFound.vue' /* webpackChunkName: "components/not-found" */).then(c => wrapFunctional(c.default || c))
export const OpenCallDetailHeader = () => import('../../components/OpenCallDetailHeader.vue' /* webpackChunkName: "components/open-call-detail-header" */).then(c => wrapFunctional(c.default || c))
export const OpenCallsItem = () => import('../../components/OpenCallsItem.vue' /* webpackChunkName: "components/open-calls-item" */).then(c => wrapFunctional(c.default || c))
export const Partners = () => import('../../components/Partners.vue' /* webpackChunkName: "components/partners" */).then(c => wrapFunctional(c.default || c))
export const PastCallsList = () => import('../../components/PastCallsList.vue' /* webpackChunkName: "components/past-calls-list" */).then(c => wrapFunctional(c.default || c))
export const PodcastCard = () => import('../../components/PodcastCard.vue' /* webpackChunkName: "components/podcast-card" */).then(c => wrapFunctional(c.default || c))
export const PressReleaseCard = () => import('../../components/PressReleaseCard.vue' /* webpackChunkName: "components/press-release-card" */).then(c => wrapFunctional(c.default || c))
export const PromoGalleryHeader = () => import('../../components/PromoGalleryHeader.vue' /* webpackChunkName: "components/promo-gallery-header" */).then(c => wrapFunctional(c.default || c))
export const PromoPhotoGalleryCard = () => import('../../components/PromoPhotoGalleryCard.vue' /* webpackChunkName: "components/promo-photo-gallery-card" */).then(c => wrapFunctional(c.default || c))
export const ResidentDetailHeader = () => import('../../components/ResidentDetailHeader.vue' /* webpackChunkName: "components/resident-detail-header" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const SocialLinks = () => import('../../components/SocialLinks.vue' /* webpackChunkName: "components/social-links" */).then(c => wrapFunctional(c.default || c))
export const TodayAtMeet = () => import('../../components/TodayAtMeet.vue' /* webpackChunkName: "components/today-at-meet" */).then(c => wrapFunctional(c.default || c))
export const ConstructorButton = () => import('../../components/Constructor/Button.vue' /* webpackChunkName: "components/constructor-button" */).then(c => wrapFunctional(c.default || c))
export const ConstructorContactPerson = () => import('../../components/Constructor/ContactPerson.vue' /* webpackChunkName: "components/constructor-contact-person" */).then(c => wrapFunctional(c.default || c))
export const ConstructorEmbed = () => import('../../components/Constructor/Embed.vue' /* webpackChunkName: "components/constructor-embed" */).then(c => wrapFunctional(c.default || c))
export const ConstructorFacebookButton = () => import('../../components/Constructor/FacebookButton.vue' /* webpackChunkName: "components/constructor-facebook-button" */).then(c => wrapFunctional(c.default || c))
export const ConstructorFile = () => import('../../components/Constructor/File.vue' /* webpackChunkName: "components/constructor-file" */).then(c => wrapFunctional(c.default || c))
export const ConstructorGoOutButton = () => import('../../components/Constructor/GoOutButton.vue' /* webpackChunkName: "components/constructor-go-out-button" */).then(c => wrapFunctional(c.default || c))
export const ConstructorImage = () => import('../../components/Constructor/Image.vue' /* webpackChunkName: "components/constructor-image" */).then(c => wrapFunctional(c.default || c))
export const ConstructorLayout = () => import('../../components/Constructor/Layout.vue' /* webpackChunkName: "components/constructor-layout" */).then(c => wrapFunctional(c.default || c))
export const ConstructorLink = () => import('../../components/Constructor/Link.vue' /* webpackChunkName: "components/constructor-link" */).then(c => wrapFunctional(c.default || c))
export const ConstructorTextBlock = () => import('../../components/Constructor/TextBlock.vue' /* webpackChunkName: "components/constructor-text-block" */).then(c => wrapFunctional(c.default || c))
export const ConstructorWarning = () => import('../../components/Constructor/Warning.vue' /* webpackChunkName: "components/constructor-warning" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/Map/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
