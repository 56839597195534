
import serp from '~/mixins/serp'
import query from '~/helpers/query'

export default {
  name: 'MeetAnnualReports',
  mixins: [serp],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/annual-report?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'report.report_image',
        'report.report_file',
      ],
    }))
    if(!data){
      redirect('/error')
    }
    
    return {
      page: data,
      reports: data.report,
      api: process.env.apiUrl
    }
  },
}
