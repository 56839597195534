
import format from '~/mixins/format'
import locale from '~/mixins/locale'
import addComa from '~/mixins/addComa'

export default {
  name: 'ResidentDetailHeader',
  mixins: [format, locale, addComa],
  props: {
    title: {
      type: String,
      default: 'title'
    },
    introtext: {
      type: String,
      default: null
    },
    countries: {
      type: Array,
      default: null
    },
    date_from: {
      type: String,
      default: null
    },
    date_to: {
      type: String,
      default: null
    },
  }
}
