
export default {
  name: 'Heading',
  props: {
    label: {
      type: String,
      default: null
    }
  } 
}
