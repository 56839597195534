
import locale from '~/mixins/locale'
import format from '~/mixins/format'
import addComa from '~/mixins/addComa'

export default {
  name: 'MobileResidentHeaderDetail',
  mixins: [format, locale, addComa],
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    introtext: {
      type: String,
      default: null
    },
    countries: {
      type: Array,
      default: null
    },
    date_from: {
      type: String,
      default: null
    },
    date_to: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  }
}
