import { render, staticRenderFns } from "./open-calls.vue?vue&type=template&id=0c969cdb&"
import script from "./open-calls.vue?vue&type=script&lang=js&"
export * from "./open-calls.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Header.vue').default,ConstructorLayout: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Constructor/Layout.vue').default,Heading: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Heading.vue').default,OpenCallsItem: require('/home/runner/work/meetfactory-2/meetfactory-2/components/OpenCallsItem.vue').default,ButtonLink: require('/home/runner/work/meetfactory-2/meetfactory-2/components/ButtonLink.vue').default})
