
import serp from '~/mixins/serp'
import isMore from '~/mixins/isMore'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'TheatreDirectors',
  mixins: [serp, isMore, getAllImages],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/theater-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'partners.image',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        key: {
          $eq: 'directors'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const theatreDirectors = await $axios.get('/api/theater-directors?' + query({
      locale: store.getters['locale'],
      pagination: {
        page: 1,
        pageSize: 6,
      }
    }))
    
    return {
      page: data[0],
      directors: theatreDirectors,
      paginationPage: 1,
      filters: {
        year: null,
        search: null,
      }
    }
  },
  watch:{
    filters:{
      handler(){
        this.directors = []
        this.paginationPage = 0
        this.loadDirectors()
      },
      deep: true
    }
  },
  methods: {
    async loadDirectors() {
      this.paginationPage++

      const filterByTitle = this.filters.search ? {
        full_name: {
          $containsi: this.filters.search
        }
      } : null

      const filterByYear = this.filters.year ? {
        event_info: {
          date_from: {
            $containsi: this.filters.year
          }
        }
      } : null

      const theatreDirectors = await this.$axios.get('/api/theater-directors?' + query({
        locale: this.$store.getters['locale'],
        filters: {
          $and: [
            {
              ...filterByYear
            },
            {
              ...filterByTitle
            }
          ]
        },
        pagination: {
          page: this.paginationPage,
          pageSize: 6,
        }
      }))
      this.directors.push(...theatreDirectors)
    },
    handleYearFilter(filteredYear) {
    this.filters.year = filteredYear
    this.filters.search = null
    },
    handleSearchFilter(filteredSearch) {
      this.filters.year = null
      this.filters.search = filteredSearch
    },
  }
}
