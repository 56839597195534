export default {
  computed: {
    today () {
      const today = new Date()
      let day = today.getDate()
      day = day.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      let month = today.getMonth() + 1
      month = month.toString()
      if (month.length < 2) {
        month = '0' + month
      }
      return today.getFullYear() + '-' + month + '-' + day
    },
  }
}