
import serp from '~/mixins/serp'
import locale from '~/mixins/locale'
import localizeUrl from '~/mixins/localizeUrl'
import query from '~/helpers/query'
import today from '~/mixins/today'
import getCategory from '~/mixins/getCategory'
import format from '~/mixins/format'

export default {
  name: 'EventDetail',
  mixins: [serp, today, getCategory, locale, localizeUrl, format],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'localizations.special_tags',
        'main_image',
        'categories',
        'event_info',
        'producer',
        'special_tags',
        'subevent.serp',
        'subevent.social.social_image',
        'subevent.subevent_content',
        'subevent.event_info',
        'subevent.producer',
        'subevent.subevent_photo_gallery.upload_images',
        'subevent.subevent_photo_gallery.promo_photo_gallery.upload_images',
        'subevent.partners.partner_logo',
        'subevent.special_tags',
        'partners.partner_logo',
        'related_events.main_image',
        'related_events.event_info',
        'related_events.categories',
        'related_events.special_tags',
        'constructor_right',
        'constructor_right.image',
        'constructor_right.upload_files',
        'constructor_right.mf_staff',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    return {
      page: data[0],
      event: data[0],
      localization: data[0]?.localizations?.data[0]?.attributes,
      api: process.env.apiUrl,
    }
  },
  computed: {
    getAllImages() {
      let galleryImages = []
      if (this.occuranceSelected !== this.event) {
        if (this.occuranceSelected.subevent_photo_gallery?.upload_images?.data?.length) {
          galleryImages = this.occuranceSelected.subevent_photo_gallery?.upload_images?.data
        } else {
          galleryImages = this.event.photo_gallery?.upload_images?.data
        }
      } else {
        galleryImages = this.event.photo_gallery?.upload_images?.data
      }
      let promo_gallery = []
      if (this.occuranceSelected !== this.event) {
        if (this.occuranceSelected.subevent_photo_gallery?.promo_photo_gallery?.data?.attributes?.upload_images?.data?.length) {
          promo_gallery = this.occuranceSelected.subevent_photo_gallery?.promo_photo_gallery?.data?.attributes?.upload_images?.data
        } else {
          promo_gallery = this.event.photo_gallery?.promo_photo_gallery?.data?.attributes?.upload_images?.data
        }
      } else {
        promo_gallery = this.event.photo_gallery?.promo_photo_gallery?.data?.attributes?.upload_images?.data
      }
      if (!galleryImages && !promo_gallery) {
        return 
      }
      if (galleryImages && !promo_gallery) {
        return galleryImages
      }
      if (!galleryImages && promo_gallery) {
        return promo_gallery
      } else {
        return [...galleryImages, ...promo_gallery]
      }
    },
    sortedRelatedEvents() {
      let sortedRelatedEvents = []
      sortedRelatedEvents = this.event.related_events?.data
      sortedRelatedEvents = sortedRelatedEvents.filter(related => (related.attributes?.event_info.date_from > this.today) || (related.attributes.event_info.date_from < this.today && related.attributes.event_info.date_to > this.today ))
      sortedRelatedEvents = sortedRelatedEvents.sort(function (a, b) {
        return (Date.parse(a.attributes?.event_info.date_from) > Date.parse(b.attributes?.event_info.date_from)) ? 1 : (Date.parse(a.attributes?.event_info.date_from) < Date.parse(b.attributes?.event_info.date_from)) ? -1 : 0;
      })
      return sortedRelatedEvents
    },
    getProducer() {
      if (this.occuranceSelected && this.occuranceSelected?.producer?.data?.attributes) {
        return this.occuranceSelected?.producer?.data?.attributes
      }
      return this.event?.producer?.data?.attributes
    }
  },
  data() {
    return {
      isMobile: false,
      occuranceSelected: this.event
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true
    }
  },
  methods: {
    handleOccuranceSelect(occuranceSelected) {
      this.occuranceSelected = occuranceSelected
      this.$router.replace({
        query: {
          date: occuranceSelected.event_info.date_from
        }
      })
    },
    handleUnselected() {
      this.occuranceSelected = this.event
      this.$router.replace({
        query: null
      })
    }
  },
  created() {
    if (this.$route.query.date && this.event.subevent.length) {
      const active = this.event.subevent.find(sub => sub.event_info.date_from === this.$route.query.date)
      if (active) {
        this.occuranceSelected = active
      } else {
        return this.occuranceSelected = this.event
      }
    } else {
      return this.occuranceSelected = this.event
    }
  },
}
