
import format from '~/mixins/format'

export default {
  name: 'PromoPhotoGallery',
  mixins: [format],
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    date_from: {
      type: String,
      default: null
    },
    date_to: {
      type: String,
      default: null
    },
    author: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  }
}
