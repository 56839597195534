
export default {
  name: "Nav",
  props: {
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    navigation() {
      if (this.$store.getters["locale"] === "cs") {
        return this.$store.getters["navigation/mainCS"];
      } else {
        return this.$store.getters["navigation/mainEN"];
      }
    },
  },
  methods: {
    changeLang() {
      this.$store.dispatch("cycleLocale");
      this.$router.replace({
        name: this.$getUrl(this.$route.name.replace("en--", "")),
      });
    },
    handleOpenMenu(item) {
      this.$emit("openMenu", item);
    },
    handleSearch() {
      this.search.length > 2 ? this.$emit("input", this.search) : null;
      this.search = "";
    },
  },
};
