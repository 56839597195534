
export default {
  name: 'BigLink',
  props: {
    label: {
      type: String,
      default: 'label'
    },
    image: {
      type: String,
      default: null
    },
    url: {
      type: [Object, String],
      default: ''
    },
    shrink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      api: process.env.apiUrl
    }
  }
}
