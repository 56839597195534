
import locale from '~/mixins/locale'
import format from '~/mixins/format'

export default {
  name: 'MobileHomepageHeroSection',
  mixins: [format, locale],
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    category: {
      type: Object,
      default: null
    },
    introtext: {
      type: String,
      default: null
    },
    date_from: {
      type: String,
      default: null
    },
    date_to: {
      type: String,
      default: null
    },
    time_from: {
      type: String,
      default: null
    },
    time_to: {
      type: String,
      default: null
    },
    producer: {
      type: String,
      default: null
    },
    tags: {
      type: [Array, Object],
      default: null
    },
    url: {
      type: [Object, String],
      default: ''
    },
    theater: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  }
}
