import { render, staticRenderFns } from "./PodcastCard.vue?vue&type=template&id=f4c9a576&scoped=true&"
import script from "./PodcastCard.vue?vue&type=script&lang=js&"
export * from "./PodcastCard.vue?vue&type=script&lang=js&"
import style0 from "./PodcastCard.vue?vue&type=style&index=0&id=f4c9a576&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4c9a576",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonLink: require('/home/runner/work/meetfactory-2/meetfactory-2/components/ButtonLink.vue').default})
