
export default {
  props: {
    components: Array
  },
  methods:{
    getComponent(name){
      switch (name) {
        case 'layout.warning':
          return 'ConstructorWarning'
        case 'layout.facebook-button':
          return 'ConstructorFacebookButton'
        case 'layout.go-out-button':
          return 'ConstructorGoOutButton'
        case 'layout.text-block':
          return 'ConstructorTextBlock'
        case 'layout.embed':
          return 'ConstructorEmbed'
        case 'layout.link':
          return 'ConstructorLink'
        case 'layout.button':
          return 'ConstructorButton'
        case 'layout.files':
          return 'ConstructorFile'
        case 'layout.image':
          return 'ConstructorImage'
        case 'layout.contact-person':
          return 'ConstructorContactPerson'
        default:
          return 'ConstructorWarning'
      }
    }
  }
}

