
import serp from '~/mixins/serp'
import query from '~/helpers/query'

export default {
  name: 'MeetPartners',
  mixins: [serp],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/partner?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'partner_sections.partners.partner_logo'
      ],
    }))
    if(!data){
      redirect('/error')
    }
    
    return {
      page: data,
      partnerSections: data.partner_sections,
      api: process.env.apiUrl
    }
  }
}
