
import serp from '~/mixins/serp'
import query from '~/helpers/query'

export default {
  name: 'ResidencyPastCalls',
  mixins: [serp],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/residency-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
      ],
      filters: {
        key: {
          $eq: 'archived-open-calls'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const pastCalls = await $axios.get('/api/open-calls?' + query({
      locale: store.getters['locale'],
      filters: {
        $and: [
          {
            category: {
              key: {
                $eq: 'residency'
              }
            }
          },
          {
            deadline_date: {
              $lt: new Date().toISOString().slice(0, 10)
            }
          }
        ]
      },
      sort: [
        'deadline_date:desc'
      ]
    }))
    
    return {
      page: data[0],
      pastCalls: pastCalls,
    }
  },
}
