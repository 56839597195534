
import styles from "./map-style.json";

export default {
  name: "Map",
  props: {
    lat: {
      type: Number,
      default: "",
    },
    lng: {
      type: Number,
      default: "",
    },
    mapUrl: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    mapStyles: styles,
    markerInfo: false,
  }),
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo({ lat: this.lat, lng: this.lng });
    });
  },
  methods: {
    recenter() {
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({ lat: this.lat, lng: this.lng });
      });
    },
    markerClick () {
      const url = this.mapUrl
      this.markerInfo = true
      window.open(url, '_blank').focus()
    }
  }
}
