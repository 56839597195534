
export default {
  name: 'Header',
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'title'
    },
    constructorLeft: {
      type: Array
    },
    constructorRight: {
      type: Array
    },
  },
  data() {
    return {
      api: process.env.apiUrl,
    }
  },
}
