
import serp from '~/mixins/serp'
import locale from '~/mixins/locale'
import localizeUrl from '~/mixins/localizeUrl'
import query from '~/helpers/query'

export default {
  name: 'SynapsePageDetail',
  mixins: [serp, locale, localizeUrl],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'subevent.subevent_content',
        'subevent.event_info',
        'subevent.subevent_photo_gallery.upload_images',
        'subevent.subevent_photo_gallery.promo_photo_gallery.upload_images',
        'subevent.partners.partner_logo',
        'subevent.special_tags',
        'partners.partner_logo',
        'constructor_right',
        'constructor_right.image',
        'constructor_right.upload_files',
        'constructor_right.mf_staff',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    return {
      page: data[0],
      event: '',
      localization: data[0]?.localizations?.data[0]?.attributes,
      api: process.env.apiUrl,
    }
  },
  computed: {
    sortedSubevents() {
      let allEvents = this.page.subevent
      if (allEvents && allEvents.length) {
        allEvents = allEvents.sort(function (a, b) {
          return (Date.parse(a.event_info.date_from) > Date.parse(b.event_info.date_from)) ? 1 : (Date.parse(a.event_info.date_from) < Date.parse(b.event_info.date_from)) ? -1 : 0;
        })
        return allEvents
      }
    },
    getAllImages() {
      let galleryImages = galleryImages = this.page.subevent.subevent_photo_gallery?.upload_images?.data
      let promo_gallery = promo_gallery = this.page.subevent.subevent_photo_gallery?.promo_photo_gallery?.data?.attributes?.upload_images?.data
      if (!galleryImages && !promo_gallery) {
        return 
      }
      if (galleryImages && !promo_gallery) {
        return galleryImages
      }
      if (!galleryImages && promo_gallery) {
        return promo_gallery
      }
      return [...galleryImages, ...promo_gallery]
    },
  },
  watch: {
    '$route.query.id'() {
      if (this.$route.query.id && this.page.subevent.length) {
        const active = this.page.subevent.find(sub => sub.id === this.$route.query.id)
        if (active) {
          this.event = active
        } return 
      } return this.event = this.event
    }
  },
  created() {
    if (this.$route.query.id && this.page.subevent.length) {
      const active = this.page.subevent.find(sub => sub.id === this.$route.query.id)
      if (active) {
        this.event = active
      } return 
    } return this.event = this.event
  }
}
