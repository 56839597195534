
import getEventState from '~/mixins/getEventState'
import locale from '~/mixins/locale'
import format from '~/mixins/format'

export default {
  name: 'CalendarItem',
  mixins: [format, locale, getEventState],
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'title'
    },
    date_from: {
      type: String,
      default: null
    },
    date_to: {
      type: String,
      default: null
    },
    time_from: {
      type: String,
      default: null
    },
    time_to: {
      type: String,
      default: null
    },
    category: {
      type: Object,
      default: null
    },
    introtext: {
      type: String,
      default: null
    },
    tags: {
      type: [Array, Object],
      default: null
    },
    producer: {
      type: String,
      default: null
    },
    url: {
      type: [Object, String],
      default: ''
    }
  },
  data() {
    return {
      api: process.env.apiUrl,
      hovered: false
    }
  }
}
