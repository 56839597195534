export default {
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
    formatTime(time) {
      return time.slice(0, -3)
    },
    formatRawDate(date) {
      const rawDate = date
      let day = rawDate.getDate()
      day = day.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      let month = rawDate.getMonth() + 1
      month = month.toString()
      if (month.length < 2) {
        month = '0' + month
      }
      return rawDate.getFullYear() + '-' + month + '-' + day
    }
  }
}