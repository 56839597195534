import Vue from 'vue'
import Router from 'vue-router'
import axios from "axios"

import Homepage from '~/pages/index'
import SearchResult from '~/pages/search-result'

import EventDetail from '~/pages/event-detail'
import OpenCallDetail from '~/pages/open-call-detail'
import TheatreDirectorDetail from '~/pages/theatre/director-detail'
import ResidencyResidentDetail from '~/pages/residency/resident-detail'
import ShapePageDetail from '~/pages/shape-detail'
import SynapsePageDetail from '~/pages/synapse-detail'
import EducationalProgramDetail from '~/pages/educational-program-detail'
import PodcastDetail from '~/pages/podcast-detail'
import PressReleaseDetail from '~/pages/press-release-detail'
import NewsDetail from '~/pages/news-detail'
import JobOfferDetail from '~/pages/job-detail'
import PromoGalleryDetail from '~/pages/promo-gallery-detail'
import GalleryImageDetail from '~/pages/gallery-image-detail'

import Music from '~/pages/music/index'
import MusicDefault from '~/pages/music/music-default'
import MusicArchive from '~/pages/music/archive'

import Gallery from '~/pages/gallery/index'
import GalleryDefault from '~/pages/gallery/gallery-default'
import GalleryAccompanyingPrograms from '~/pages/gallery/accompanying-programs'
import GalleryArchive from '~/pages/gallery/archive'
import GalleryConcept from '~/pages/gallery/concept'
import GalleryOpenCalls from '~/pages/gallery/open-calls'
import GalleryPastCalls from '~/pages/gallery/past-calls'

import Residency from '~/pages/residency/index'
import ResidencyResidents from '~/pages/residency/residents'
import ResidencyOpenCalls from '~/pages/residency/open-calls'
import ResidencyPastCalls from '~/pages/residency/past-calls'
import ResidencyStudio from '~/pages/residency/studio'

import Theatre from '~/pages/theatre/index'
import TheatreRepertorie from '~/pages/theatre/repertoire'
import TheatreDramaturgy from '~/pages/theatre/dramaturgy'
import TheatreAudioPlays from '~/pages/theatre/audio-plays'
import TheatreArchive from '~/pages/theatre/archive'
import TheatreDirectors from '~/pages/theatre/directors'
import TheatreDefault from '~/pages/theatre/theatre-default'

import Synapse from '~/pages/synapse/index'
import SynapseAgain from '~/pages/synapse/synapse-again'
import SynapseArchive from '~/pages/synapse/archive'
import SynapseDefault from '~/pages/synapse/synapse-default'

import Shape from '~/pages/shape/index'
import ShapeEvents from '~/pages/shape/shape-events'
import ShapeDefault from '~/pages/shape/shape-default'

import Default from '~/pages/meetfactory/meet-default'
import AnnualReports from '~/pages/meetfactory/meet-annual-reports'
import EducationalPrograms from '~/pages/meetfactory/meet-educational-programs'
import OtherEvents from '~/pages/meetfactory/meet-other-events'
import SummerCinema from '~/pages/meetfactory/meet-summer-cinema'
import About from '~/pages/meetfactory/meet-about'
import Podcasts from '~/pages/meetfactory/meet-podcasts'
import Partners from '~/pages/meetfactory/meet-partners'
import Contacts from '~/pages/meetfactory/meet-contact'
import Press from '~/pages/meetfactory/meet-press'
import Terms from '~/pages/meetfactory/meet-terms'
import PersonalData from '~/pages/meetfactory/meet-personal-data'
import News from '~/pages/meetfactory/meet-news'
import JobOffers from '~/pages/meetfactory/meet-jobs'
import PromoGallery from '~/pages/meetfactory/meet-promo-gallery'

Vue.use(Router)

export async function createRouter() {

  const apiUrl = process.env.apiUrl

  const { data } = await axios.get(apiUrl + '/api/custom/routes')

  const routersFromApi = generateRouting(data);

  return new Router({
    scrollBehavior(to, from, savedPosition) {
      const defaultPosition = false
      const scrollTopPosition = { x: 0, y: 0 }
      let position = defaultPosition
      if (savedPosition) {
        position = savedPosition
      }
      else if (to.hash && document.querySelector(to.hash)) {
        position = { selector: to.hash }
      }
      position = scrollTopPosition

      return new Promise((resolve) => {
        resolve(position)
      })
    },
    mode: 'history',
    routes: [
      {
        name: 'homepage',
        path: '/',
        component: Homepage
      },
      {
        name: 'en--homepage',
        path: '/en/',
        component: Homepage
      },
      {
        name: 'search',
        path: '/vyhledavani/:search',
        component: SearchResult
      },
      {
        name: 'en--search',
        path: '/en/search/:search',
        component: SearchResult
      },
      {
        name: "music",
        path: "/hudba",
        component: Music,
      },
      {
        name: "en--music",
        path: "/en/music",
        component: Music,
      },
      {
        name: "gallery",
        path: "/galerie",
        component: Gallery,
      },
      {
        name: "en--gallery",
        path: "/en/gallery",
        component: Gallery,
      },
      {
        name: "theater",
        path: "/divadlo",
        component: Theatre,
      },
      {
        name: "en--theater",
        path: "/en/theater",
        component: Theatre,
      },
      {
        name: "residency",
        path: "/rezidence",
        component: Residency,
      },
      {
        name: "en--residency",
        path: "/en/residency",
        component: Residency,
      },
      {
        name: "meetfactory",
        path: "/meetfactory",
        component: OtherEvents,
      },
      {
        name: "en--meetfactory",
        path: "/en/meetfactory",
        component: OtherEvents,
      },
      ...routersFromApi,
    ]
  })
}

const getCategAlias = (category) => {
  let cs = "";
  let en = "";
  if (category === "music-page") {
    cs = "/hudba/";
    en = "/en/music/";
  }
  if (category === "gallery-page") {
    cs = "/galerie/";
    en = "/en/gallery/";
  }
  if (category === "theater-page") {
    cs = "/divadlo/";
    en = "/en/theater/";
  }
  if (category === "residency-page") {
    cs = "/rezidence/";
    en = "/en/residency/";
  }
  if (category === "meet-factory-page") {
    cs = "/";
    en = "/en/";
  }
  if (category === "press") {
    cs = "/";
    en = "/en/";
  }
  if (category === "partner") {
    cs = "/";
    en = "/en/";
  }
  if (category === "contacts") {
    cs = "/";
    en = "/en/";
  }
  if (category === "annual-report") {
    cs = "/";
    en = "/en/";
  }
  if (category === "synapse-page") {
    cs = "/";
    en = "/en/";
  }
  if (category === "shape-page") {
    cs = "/";
    en = "/en/";
  }
  return {
    cs,
    en,
  };
};

const getComponent = (route) => {

  if (route.category === "music-page") {
    if (route.key === "program") return Music
    if (route.key === "archive") return MusicArchive
    return MusicDefault
  }

  if (route.category === "gallery-page") {
    if (route.key === "program") return Gallery
    if (route.key === "concept") return GalleryConcept
    if (route.key === "kostka") return GalleryDefault
    if (route.key === "meet") return GalleryDefault
    if (route.key === "archive") return GalleryArchive
    if (route.key === "accompanying-programs") return GalleryAccompanyingPrograms
    if (route.key === "open-calls") return GalleryOpenCalls
    if (route.key === "past-calls") return GalleryPastCalls
    return GalleryDefault
  }

  if (route.category === "residency-page") {
    if (route.key === "program") return Residency
    if (route.key === "residents") return ResidencyResidents
    if (route.key === "open-calls") return ResidencyOpenCalls
    if (route.key === "archived-open-calls") return ResidencyPastCalls
    return ResidencyStudio
  }

  if (route.category === "theater-page") {
    if (route.key === "program") return Theatre
    if (route.key === "current-repertoire") return TheatreRepertorie
    if (route.key === "dramaturgy") return TheatreDramaturgy
    if (route.key === "audio") return TheatreAudioPlays
    if (route.key === "archive") return TheatreArchive
    if (route.key === "directors") return TheatreDirectors
    return TheatreDefault
  }

  if (route.category === "synapse-page") {
    if (route.key === "program") return Synapse
    if (route.key === "archive") return SynapseArchive
    if (route.key === "synapse-again") return SynapseAgain
    return SynapseDefault
  }

  if (route.category === "shape-page") {
    if (route.key === "program") return Shape
    if (route.key === "events") return ShapeEvents
    return ShapeDefault
  }

  if (route.category === "meet-factory-page") {
    if (route.key === "podcasts") return Podcasts
    if (route.key === "about") return About
    if (route.key === "more-events") return OtherEvents
    if (route.key === "cinema") return SummerCinema
    if (route.key === "promo-photo") return PromoGallery
    if (route.key === "educational-programs") return EducationalPrograms
    if (route.key === "jobs") return JobOffers
    if (route.key === "news") return News
    if (route.key === "terms") return Terms
    if (route.key === "personal-data") return PersonalData
    return Default
  }

  if (route.category === "press") {
    return Press
  }

  if (route.category === "partner") {
    return Partners
  }

  if (route.category === "contacts") {
    return Contacts
  }

  if (route.category === "annual-report") {
    return AnnualReports
  }

  return null
}

function generateRouting(routes) {
  const result = []

  routes.forEach((route) => {
    const { category } = route
    const alias = getCategAlias(category)
    const component = getComponent(route)

    const csRoute = {
      name: category + "-" + (route.key || route.url_cs),
      path: alias.cs + route.url_cs,
      component,
    }

    const enRoute = {
      name: "en--" + category + "-" + (route.key || route.url_cs),
      path: alias.en + route.url_en,
      component,
    }

    result.push(csRoute, enRoute)

    if (route.category === "music-page") {
      if (!route.key) return
      if (
        route.key === "program" ||
        route.key === "archive"
      ) {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: EventDetail,
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: EventDetail,
        };
        result.push(csRoute, enRoute)
      }
    }
    if (route.category === "gallery-page") {
      if (!route.key) return
      if (
        route.key === "program" ||
        route.key === "kostka" ||
        route.key === "meet" ||
        route.key === "kostka" ||
        route.key === "meet" ||
        route.key === "open-calls" ||
        route.key === "past-calls" ||
        route.key === "archive"
      ) {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: route.key === "open-calls" ? OpenCallDetail : route.key === "open-calls" ? OpenCallDetail : EventDetail,
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: route.key === "open-calls" ? OpenCallDetail : route.key === "open-calls" ? OpenCallDetail : EventDetail,
        };
        result.push(csRoute, enRoute)
      }
    }
    if (route.category === "residency-page") {
      const getComp = (key) => {
        if (route.key === "residents") return ResidencyResidentDetail
        if (route.key === "open-calls") return OpenCallDetail
        if (route.key === "archived-open-calls") return OpenCallDetail
        return EventDetail
      };
      if (!route.key) return
      if (
        route.key === "program" ||
        route.key === "residents" ||
        route.key === "open-calls" ||
        route.key === "archived-open-calls"
      ) {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: getComp(route.key),
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: getComp(route.key),
        };
        result.push(csRoute, enRoute)
      }
    }
    if (route.category === "theater-page") {
      if (!route.key) return
      if (
        route.key === "program" ||
        route.key === "current-repertoire" ||
        route.key === "audio" ||
        route.key === "directors" ||
        route.key === "archive"
      ) {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: route.key === "directors" ? TheatreDirectorDetail : EventDetail,
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: route.key === "directors" ? TheatreDirectorDetail : EventDetail,
        };
        result.push(csRoute, enRoute)
      }
    }
    if (route.category === "synapse-page") {
      if (!route.key) return
      if (
        route.key === "program" ||
        route.key === "archive"
      ) {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: SynapsePageDetail,
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: SynapsePageDetail,
        };
        result.push(csRoute, enRoute)
      }
    }
    if (route.category === "shape-page") {
      if (!route.key) return
      if (route.key === "events") {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: ShapePageDetail,
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: ShapePageDetail,
        };
        result.push(csRoute, enRoute)
      }
    }
    if (route.category === "meet-factory-page") {
      if (!route.key) return
      if (route.key === "podcasts") {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: PodcastDetail,
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: PodcastDetail,
        };
        result.push(csRoute, enRoute)
      }
      if (route.key === "cinema") {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: EventDetail,
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: EventDetail,
        };
        result.push(csRoute, enRoute)
      }
      if (route.key === "educational-programs") {
        const csRoute = {
          name: category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.cs + route.url_cs + "/:slug",
          component: EducationalProgramDetail,
        };
        const enRoute = {
          name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
          path: alias.en + route.url_en + "/:slug",
          component: EducationalProgramDetail,
        };
        result.push(csRoute, enRoute);
      }
    }
    if (route.key === "promo-photo") {
      const csRoute = {
        name: category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.cs + route.url_cs + "/:slug",
        component: PromoGalleryDetail,
      };
      const enRoute = {
        name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.en + route.url_en + "/:slug",
        component: PromoGalleryDetail,
      };
      const csRoute2 = {
        name: category + "-" + (route.key || route.url_cs) + "-detail-gallery",
        path: alias.cs + route.url_cs + "/:slug/galerie",
        component: GalleryImageDetail,
      };
      const enRoute2 = {
        name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail-gallery",
        path: alias.en + route.url_en + "/:slug/gallery",
        component: GalleryImageDetail,
      };
      result.push(csRoute, enRoute, csRoute2, enRoute2)
    }
    if (route.key === "more-events") {
      const csRoute = {
        name: category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.cs + route.url_cs + "/:slug",
        component: EventDetail,
      };
      const enRoute = {
        name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.en + route.url_en + "/:slug",
        component: EventDetail,
      };
      result.push(csRoute, enRoute)
    }

    if (route.key === "news") {
      const csRoute = {
        name: category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.cs + route.url_cs + "/:slug",
        component: NewsDetail,
      };
      const enRoute = {
        name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.en + route.url_en + "/:slug",
        component: NewsDetail,
      };
      result.push(csRoute, enRoute);
    }

    if (route.key === "jobs") {
      const csRoute = {
        name: category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.cs + route.url_cs + "/:slug",
        component: JobOfferDetail,
      };
      const enRoute = {
        name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.en + route.url_en + "/:slug",
        component: JobOfferDetail,
      };
      result.push(csRoute, enRoute);
    }

    if (route.category === "press") {
      const csRoute = {
        name: category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.cs + route.url_cs + "/:slug",
        component: PressReleaseDetail,
      };
      const enRoute = {
        name: "en--" + category + "-" + (route.key || route.url_cs) + "-detail",
        path: alias.en + route.url_en + "/:slug",
        component: PressReleaseDetail,
      };
      result.push(csRoute, enRoute);
    }
  })

  return result;
}
