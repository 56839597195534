
export default {
  name: 'JobOfferCard',
  props: {
    title: {
      type: String,
      default: null
    },
    introtext: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: ''
    }
  }
}
