import deepmerge from 'deepmerge'
import qs from 'qs'

export const queryBase = () => {
  return {
    locale: [],
    populate: [],
    sort: [],
    filters: {},
    pagination: {},
  }
}

const DefaultOptions = { encodeValuesOnly: true }

export default (params = {}, options = DefaultOptions) => {
  const base = queryBase()

  // In case no populate is defined, use '*' as default
  if (!base.populate.length && (!params.populate || !params.populate.length)) {
    base.populate = '*'
  }

  return qs.stringify(deepmerge(base, params), options)
}
