
import serp from '~/mixins/serp'
import isMore from '~/mixins/isMore'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'ResidencyResidents',
  mixins: [serp, isMore, getAllImages],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/residency-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'countries',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'partners.image',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        key: {
          $eq: 'residents'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const residents = await $axios.get('/api/residents?' + query({
      locale: store.getters['locale'],
      sort: [
        'date_from:desc'
      ],
      pagination: {
        page: 1,
        pageSize: 6,
      }
    }))
    
    return {
      page: data[0],
      residents: residents,
      paginationPage: 1,
      filters: {
        year: null,
        country: null,
        search: null,
        range: null
      }
    }
  },
  mounted() {
    console.log(this.residents)
  },
  watch:{
    filters:{
      handler(){
        this.residents = []
        this.paginationPage = 0
        this.loadResidents()
      },
      deep: true
    }
  },
  methods: {
    async loadResidents() {
      this.paginationPage++

      let filterByRange = 
      this.filters.range === 'current' ? 
      {
        $and: [
          {
            date_to: {
              $gte: new Date().toISOString().slice(0, 10)
            },
          },
          {
            date_from: {
              $lte: new Date().toISOString().slice(0, 10)
            }
          }
        ]
      } : 
      this.filters.range === 'future' ? 
      {
        date_from: {
          $gt: new Date().toISOString().slice(0, 10)
        }
      } : 
      this.filters.year ? {
        $or: [
          {
            date_from: {
              $containsi: this.filters.year
            },
          },
          {
            date_to: {
              $containsi: this.filters.year
            },
          }
        ]
      } : null

      const filterByTitle = this.filters.search ? {
        full_name: {
          $containsi: this.filters.search
        }
      } : null

      const filterByCountry = this.filters.country ? {
        countries: {
          $or: [
            {
              name: {
                $eqi: this.filters.country
              }
            },
            {
              name_en: {
                $eqi: this.filters.country
              }
            }
          ]
        }
      } : null

      const residents = await this.$axios.get('/api/residents?' + query({
        locale: this.$store.getters['locale'],
        filters: {
          $and: [
            {
              ...filterByRange
            },
            {
              ...filterByCountry
            },
            {
              ...filterByTitle
            }
          ]
        },
        sort: [
          'date_from:desc'
        ], 
        pagination: {
          page: this.paginationPage,
          pageSize: 6,
        }
      }))
      this.residents.push(...residents)
    },
    handleYearFilter(filteredYear) {
      this.filters.year = filteredYear
      this.filters.range = null
      this.filters.search = null
    },
    handleCountryFilter(filteredCountry) {
      this.filters.country = filteredCountry
      this.filters.range = null
      this.filters.search = null
    },
    handleRangeFilter(filteredRange) {
      this.filters.year = null
      this.filters.country = null
      this.filters.range = filteredRange
      this.filters.search = null
    },
    handleSearchFilter(filteredSearch) {
      this.filters.year = null
      this.filters.country = null
      this.filters.range = null
      this.filters.search = filteredSearch
    },
  },
}
