
import serp from '~/mixins/serp'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'MeetPress',
  mixins: [serp, getAllImages],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/meet-factory-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'partners.image',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        key: {
          $eq: 'news'
        }
      }
    }))
    if(!data){
      redirect('/error')
    }

    const news = await $axios.get('/api/news?' + query({
      locale: store.getters['locale'],
      sort: [
        'publishedAt:desc'
      ],
      pagination: {
        page: 1,
        pageSize: 6,
      }
    }))
    
    return {
      page: data[0],
      news: news,
      paginationPage: 1,
    }
  },
  methods: {
    async loadNews() {
      this.paginationPage++

      const news = await this.$axios.get('/api/news?' + query({
        locale: this.$store.getters['locale'],
        sort: [
          'publishedAt:desc'
        ],
        pagination: {
          page: this.paginationPage,
          pageSize: 6,
        }
      }))

      this.news.push(...news)
    },
  }
}
