
import locale from '~/mixins/locale'

export default {
  name: 'TodayAtMeet',
  mixins: [locale],
  props: {
    title: {
      type: String,
      default: 'title'
    },
    category: {
      type: Object,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    time: {
      type: String,
      default: null
    },
    url: {
      type: [Object, String],
      default: ''
    },
    state: {
      type: Array,
      default: null
    }
  }
}
