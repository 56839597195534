
import format from '~/mixins/format'

export default {
  name: 'OpenCallsItem',
  mixins: [format],
  props: {
    broadcast: {
      type: String,
      default: null
    },
    deadline: {
      type: String,
      default: null
    },
    results: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    url: {
      type: [String, Object],
      default: null
    }
  }
}
