import { render, staticRenderFns } from "./archive.vue?vue&type=template&id=626c2c07&"
import script from "./archive.vue?vue&type=script&lang=js&"
export * from "./archive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Header.vue').default,FilterEvents: require('/home/runner/work/meetfactory-2/meetfactory-2/components/FilterEvents.vue').default,EventCard: require('/home/runner/work/meetfactory-2/meetfactory-2/components/EventCard.vue').default,NotFound: require('/home/runner/work/meetfactory-2/meetfactory-2/components/NotFound.vue').default,Button: require('/home/runner/work/meetfactory-2/meetfactory-2/components/Button.vue').default})
