
import serp from '~/mixins/serp'
import isMore from '~/mixins/isMore'
import today from '~/mixins/today'
import format from '~/mixins/format'
import getAllImages from '~/mixins/getAllImages'
import query from '~/helpers/query'

export default {
  name: 'TheatreIndex',
  mixins: [serp, isMore, today, format, getAllImages],
  async asyncData({ $axios, redirect, store }) {
    const data = await $axios.get('/api/theater-pages?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_left.mf_staff',
        'constructor_right.mf_staff',
        'constructor_right.image',
        'constructor_right.upload_files',
        'partners.image',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        key: {
          $eq: 'program'
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    const eventsTheatre = await $axios.get('/api/events?' + query({
      locale: store.getters['locale'],
      populate: [
        'main_image',
        'categories',
        'event_info',
        'producer',
        'special_tags',
        'subevent.event_info',
        'subevent.special_tags',
        'subevent.producer',
      ],
      filters: {
        $or: [
          {
            $and: [
              {
                categories: {
                  key: {
                    $eq: 'theater'
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          },
          {
            $and: [
              {
                categories: {
                  key: {
                    $eq: 'theater'
                  }
                }
              },
              {
                event_info: {
                  date_from: {
                    $lte: new Date().toISOString().slice(0, 10)
                  }
                }
              },
              {
                event_info: {
                  date_to: {
                    $gte: new Date().toISOString().slice(0, 10)
                  }
                }
              }
            ]
          }
        ]
      },
      sort: [
        'event_info.date_from:asc'
      ],
      pagination: {
        page: 1,
        pageSize: 12,
      }
    }))
    
    return {
      page: data[0],
      events: eventsTheatre,
      allEvents: [],
      paginationPage: 1,
      filters: {
        date: null,
        search: null,
        range: null
      }
    }
  },
  watch:{
    filters:{
      handler(){
        this.events = []
        this.allEvents = []
        this.paginationPage = 0
        this.loadEvents()
      },
      deep: true
    }
  },
  data() {
    return {
      isMobile: false
    }
  },
  mounted() {
    if (this.$isMobile()) {
      this.isMobile = true
    }
    this.getAllEvents()
  },
  methods: {
    async loadEvents() {
      this.paginationPage++

      const eventsTheatre = await this.$axios.get('/api/events?' + query({
        locale: this.$store.getters['locale'],
        populate: [
          'main_image',
          'categories',
          'event_info',
          'special_tags',
          'subevent.event_info',
          'subevent.special_tags',
          'subevent.producer'
        ],
        filters: {
          $or: [
            {
              $and: [
                {
                  categories: {
                    key: {
                      $eq: 'theater'
                    }
                  }
                },
                {
                  event_info: {
                    date_from: {
                      $gte: new Date().toISOString().slice(0, 10)
                    }
                  }
                }
              ]
            },
            {
              $and: [
                {
                  categories: {
                    key: {
                      $eq: 'theater'
                    }
                  }
                },
                {
                  event_info: {
                    date_from: {
                      $lte: new Date().toISOString().slice(0, 10)
                    }
                  }
                },
                {
                  event_info: {
                    date_to: {
                      $gte: new Date().toISOString().slice(0, 10)
                    }
                  }
                }
              ]
            }
          ]
        },
        sort: [
          'event_info.date_from:asc'
        ],
        pagination: {
          page: this.paginationPage,
          pageSize: 12,
        }
      }))
      this.events.push(...eventsTheatre)
      this.getAllEvents()
    },
    getAllEvents() {
      let allEvents = []
      if (this.events.filter(play => play.subevent.length <= 0)[0] != null) {
        allEvents.push(this.events.filter(play => play.subevent.length <= 0)[0])
      }
      if (this.events.filter(event => event.subevent.length > 0 != null)) {
        this.events.filter(event => event.subevent.length > 0).forEach(event => {
          event.subevent.forEach(occurance => {
            occurance.main_image = event.main_image
            occurance.categories = event.categories
            occurance.introtext = event.introtext
            occurance.url_alias = event.url_alias
            allEvents.push(occurance)
          })
        })
      }
      allEvents = allEvents.filter(event => event.event_info.date_from >= this.today || event.event_info.date_from <= this.today && event.event_info.date_to >= this.today)
      this.filters.date ? allEvents = allEvents.filter(event => event.event_info.date_from === this.filters.date || event.event_info.date_from <= this.filters.date && event.event_info.date_to >= this.filters.date) : null
      this.filters.range ? allEvents = allEvents.filter(event => {
        return (
          event.event_info.date_from >= new Date().toISOString().slice(0, 10) && event.event_info.date_from <= new Date(new Date().getTime() + (this.filters.range * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10) ||
          event.event_info.date_from <= new Date(new Date().getTime() + (this.filters.range * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10) && event.event_info.date_to >= new Date(new Date().getTime() + (this.filters.range * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10) ||
          event.event_info.date_to >= new Date().toISOString().slice(0, 10) && event.event_info.date_to <= new Date(new Date().getTime() + (this.filters.range * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
        )
      }) : null
      this.filters.search ? allEvents = allEvents.filter(event => event.page_title.includes(this.filters.search)) : null
      allEvents = allEvents.sort(function (a, b) {
        return (Date.parse(a.event_info.date_from) > Date.parse(b.event_info.date_from)) ? 1 : (Date.parse(a.event_info.date_from) < Date.parse(b.event_info.date_from)) ? -1 : 0;
      })
      return this.allEvents = allEvents
    },
    handleDateFilter(filteredDate) {
      this.filters.date = filteredDate
      this.filters.range = null
      this.filters.search = null
    },
    handleRangeFilter(filteredRange) {
      this.filters.date = null
      this.filters.range = filteredRange
      this.filters.search = null
    },
    handleSearchFilter(filteredSearch) {
      this.filters.date = null
      this.filters.range = null
      this.filters.search = filteredSearch
    },
  },
}
