
import serp from '~/mixins/serp'
import localizeUrl from '~/mixins/localizeUrl'
import locale from '~/mixins/locale'
import getAllImages from '~/mixins/getAllImages'
import format from '~/mixins/format'
import query from '~/helpers/query'

export default {
  name: 'NewsDetail',
  mixins: [serp, format, locale, localizeUrl, getAllImages],
  async asyncData({ $axios, route, redirect, store }) {
    const slug = route.params.slug
    const data = await $axios.get('/api/news?' + query({
      locale: store.getters['locale'],
      populate: [
        'serp',
        'social_sharing.social_image',
        'localizations',
        'main_image',
        'constructor_left.image',
        'constructor_left.upload_files',
        'constructor_right.image',
        'constructor_right.upload_files',
        'constructor_right.mf_staff',
        'photo_gallery.upload_images',
        'photo_gallery.promo_photo_gallery.upload_images',
      ],
      filters: {
        url_alias: {
          $eq: slug
        }
      }
    }))
    if(!data?.length){
      redirect('/error')
    }

    return {
      page: data[0],
      localization: data[0]?.localizations?.data[0]?.attributes,
      api: process.env.apiUrl,
    }
  },
}
