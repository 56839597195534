
export default {
  methods: {
    addComa(word, index, length) {
      if (index < length - 1) {
        return word + ','
      } else {
        return word
      }
    }
  }
}